import React, { Fragment } from "react";
import {
    useTable,
    useGlobalFilter,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
    useRowSelect
} from "react-table";
import { withTranslation } from 'react-i18next';
import { ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, Input, Table, UncontrolledDropdown } from "reactstrap";
import SimpleBar from "simplebar-react";

interface TableContainerProps {
    columns?: any;
    data?: any;
    updateRow: any;
    onUpdate: any;
    tableClass: string,
    theadClass: string,
    thClass: string,
    importJson: any
}

const TableContainer = ({
    columns,
    data,
    updateRow,
    onUpdate,
    tableClass,
    theadClass,
    thClass,
    importJson
}: TableContainerProps) => {
    const {
        getTableProps,
        headerGroups,
        page,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0, pageSize: 500
            },
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );
    return (
        <div>
            <Table responsive={true} hover {...getTableProps()} className={tableClass +'m-0'}>
                <thead  className={theadClass}>
                    {headerGroups && headerGroups.length ? headerGroups.map((headerGroup: any) => (
                        <tr key={headerGroup.id}  {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                <th key={column.id} className={thClass + " fw-bold"}
                                    {...column.isSortable ? { ...column.getSortByToggleProps() } : ""}

                                >
                                    <div title={column.Header}>
                                        {column.render("Header")}
                                    </div>
                                </th>
                            ))}
                            <th className={thClass + " fw-bold"} >
                                Manage existing values
                            </th>
                        </tr>
                    )) : null}
                </thead>
                <tbody>
                {page.map((row: any, index: any) => {
                    prepareRow(row);
                    const options = row.original.options;   
                    const currentValue = updateRow?.id ===  row.original?.id ? updateRow?.field : row.original?.field;   
                    const checked = updateRow?.id ===  row.original?.id ? updateRow?.overwrite : row.original?.overwrite;  
                    return (
                        <Fragment key={row.getRowProps().key}>
                            <tr style={{ verticalAlign: 'middle', maxHeight: 50 }}>
                                {row.cells.map((cell: any) => {
                                    if (cell.column.id === 'suggestedFields') {                                         
                                        return <td style={{width:250}}>
                                            <ButtonGroup className="w-100">
                                                <UncontrolledDropdown>
                                                    <DropdownToggle
                                                        tag="button"
                                                        title={currentValue && currentValue.label ? currentValue.label : 'Select Zilter field to Map...'}
                                                        className="btn btn-soft-dark text-left d-flex align-items-center justify-content-between bg-body-secondary border-dark border-opacity-25"
                                                        style={{width:250, color: 'inherit' }}
                                                    >
                                                        <span className="text-nowrap text-truncate text-nowrap-ellipsis">{currentValue && currentValue.label ? currentValue.label : 'Select Zilter field to Map...'}</span> <i className="mdi mdi-chevron-down"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdownmenu-primary border-dark border-opacity-50">
                                                        <SimpleBar style={{maxHeight: 300, width:250}} >
                                                            {options && options.length ? options.map((option: any, index: number) => (
                                                                <DropdownItem
                                                                    key={index}    
                                                                    title={option ? option.label : ''}                                                                
                                                                    onClick={(e) => onUpdate(row.original, option, "field", row.original.id)}
                                                                >
                                                                    {option ? option.label : ''}
                                                                </DropdownItem>
                                                            )) : null}
                                                        </SimpleBar>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </ButtonGroup>
                                        </td>
                                    }
                                    else return <td key={cell.id} style={{ verticalAlign: 'middle', minWidth:100 }} className='align-items-center justify-content-between' {...cell.getCellProps()}>
                                        {cell.render("Cell")}
                                    </td>
                                })}  
                                <td>
                                    <ul className="list-inline hstack gap-2 mb-0">
                                        <li className="list-inline-item flex-wrap hstack gap-1 align-items-center">
                                            <div className='hstack'>
                                                <Input type='checkbox' disabled={importJson.importAction === 'update' ? true : false } checked={!checked}  onClick={(e: any) => onUpdate(row.original, !e.target?.checked, "overwrite", row.original.id)} />
                                                <div>Don't Overwrite</div>
                                            </div>                                            
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </Fragment>
                    );
                })}
                </tbody>
            </Table>
        </div>
    );
};

export default withTranslation()(TableContainer);