import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { ApplicationState } from "store";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getStudentsFootprints } from "store/student/action";
import { isJson } from "utils";
import { Card, Label } from "reactstrap";
import moment from "moment";
import SimpleBar from "simplebar-react";

interface Log {
    id: string;
    studentId: string;
    createdAt: string;
    valuesJson: {
        action: string;
        field: string;
        newValue: string;
        oldValue: string;
        userName: string;
        timestamp: string;
    };
}

const Logs = () => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const footprints: any = useSelector((state: ApplicationState) => state.student.footprints);
    const [footprintsLogs, setFootprintsLogs] = useState<any>()
    const [parsedJson, setParsedJson] = useState<Log[]>([]);

    useEffect(() => {
        dispatch(getStudentsFootprints())
    }, [])

    useEffect(() => {
        if (footprints) {
            setFootprintsLogs(footprints)
        }
    }, [footprints])

    useEffect(() => {
        if (footprintsLogs && footprintsLogs.length) {
            const parsedData = footprintsLogs.map((item: any) => {
                try {
                    const { studentId, createdAt, id, valuesJson } = item;
                    let parsedValuesJson = valuesJson;
                    if (typeof valuesJson === 'string') {
                        parsedValuesJson = JSON.parse(valuesJson);
                    }
                    if (typeof parsedValuesJson === 'string' && isJson(parsedValuesJson)) {
                        parsedValuesJson = JSON.parse(parsedValuesJson);
                    }
                    return {
                        studentId,
                        createdAt,
                        id,
                        valuesJson: parsedValuesJson,
                    };
                } catch (error) {
                    console.error("Error parsing JSON:", error, item);
                    return null;
                }
            }).filter(Boolean);

            setParsedJson(parsedData);
        }
    }, [footprintsLogs]);

    return (
        <>
            <SimpleBar style={{ maxHeight: '500px' }}>
                {parsedJson.map((log, index) => (
                    <Card key={index}>
                        <div className="mx-3 my-2">
                            <Label className="fw-bold">
                                {log?.valuesJson?.userName}
                            </Label> <span>{log?.valuesJson?.action}</span> <Label className="fw-bold">
                                {log?.valuesJson?.field}
                            </Label> <span className="text-muted">
                                at {moment(log?.createdAt).format("MMMM D, YYYY [at] h:mm A")}
                            </span>
                        </div>
                        {log?.valuesJson?.action === "updated" ? (
                            <div>
                                {log?.valuesJson?.newValue} 
                                {/* <i className="ri-arrow-right-line"></i>  */}
                                {log?.valuesJson?.oldValue}
                            </div>
                        ) : null }
                    </Card>
                ))}
            </SimpleBar>
        </>

    );
}
export default Logs;

//   <div>
//     <Card className="p-2"  key={index}>
//     <p>Action: {log?.valuesJson?.action}</p>
//     <p>Field: {log?.valuesJson?.field}</p>
//     <p>New Value: {log?.valuesJson?.newValue}</p>
//     <p>Old Value: {log?.valuesJson?.oldValue}</p>
//     <p>Timestamp: {log?.valuesJson?.timestamp}</p>
//     <p>User: {log?.valuesJson?.userName}</p>
//     <p>Student ID: {log?.studentId}</p>
//     <p>Created At: {log?.createdAt}</p>
//     <p>Log ID: {log?.id}</p>
//     </Card>

//   </div>