import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Accordion, Label } from "reactstrap";
import { DNDColumn } from "../../DNDColumn";
import Empty from "./Empty";

interface FieldsProps {
    fields: any;
    moveFields: (dragIndex: number, hoverIndex: number, isDragging: boolean) => void
    onFieldUpdated: (newfilter: any) => void
    onFieldDelete: (deleteColumn: any) => void
    t: (key: string) => string;
}

const Fields: React.FC<FieldsProps> = ({ t, fields, moveFields, onFieldUpdated, onFieldDelete }) => {
    const [openDefault, setOpenDefault] = useState('');
    const toggleDefault = (id: any) => {
        if (openDefault !== id) {
            setOpenDefault(id);
        } else {
            setOpenDefault('');
        }
    };
    return (
        <div className="d-flex flex-column gap-1 pt-3 mt-3 border-top-dotted border-opacity-25 border-dark">
            <Label className="m-0 fw-bold fs-5">Fields</Label>
            <div className="d-flex flex-column gap-2 overflow-y-scroll">
                <Accordion className="d-flex flex-column gap-1" id="default-accordion-example" open={openDefault} toggle={toggleDefault}>
                    <DndProvider backend={HTML5Backend}>
                        {
                            fields.map((field: any, valueIndex: number) => {
                                return (
                                    <DNDColumn
                                        accordionId={valueIndex.toString()}
                                        key={field.value}
                                        index={valueIndex}
                                        id={field.value}
                                        column={field}
                                        moveColumns={moveFields}
                                        toggle={toggleDefault}
                                        onColumnUpdated={onFieldUpdated}
                                        onColumnDelete={onFieldDelete}
                                    />
                                )
                            })
                        }
                    </DndProvider>
                </Accordion>
            </div>
            <Empty message="Add Field here" />
        </div>
    )
};

export default withTranslation()(Fields);
