import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Input, Label, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { reportSaveRequest } from "store/report/action";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { useNavigate } from "react-router";
import { ApplicationState } from "store";

interface SaveModelProps {
    config: any;
    saveModel: boolean;
    setSaveModel: (saveModel: boolean) => void;
    t: (key: string) => string;
}

const SaveModel: React.FC<SaveModelProps> = ({
    config,
    saveModel,
    setSaveModel,
    t,
}) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const loading = useSelector((state: ApplicationState) => state.report.report_save_loading)
    const navigate = useNavigate();
    
    const [reportName, setReportName] = useState('')

    const onSaveReport = () => {
        const data = {
            name: reportName,
            config: config
        }
        const handleSuccess = () => {
            setSaveModel(false);
            toast(`Report saved successfully.`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            navigate('/reports');
        }
        const handleError = () => {
            toast(`Error saving report.`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
        }
        dispatch(reportSaveRequest(data, handleSuccess, handleError))
    }
    return (
        <>
            <ToastContainer />
            <Modal isOpen={saveModel} toggle={() => loading ? null : setSaveModel(false)} backdrop={'static'} centered>
                <ModalHeader className="bg-light p-3" toggle={() => loading ? null : setSaveModel(false)}>
                    Save Report
                </ModalHeader>
                    <ModalBody>
                        <div className="d-flex flex-column gap-2 p-3">
                            <div className="form-group mb-3">
                                <Label className="form-label fw-bold fs-6">
                                    Name
                                </Label>
                                <Input
                                    type="text"
                                    placeholder='Enter report name'
                                    onChange={(event) => setReportName(event?.target.value)}
                                    value={reportName}
                                />
                            </div>
                        </div>
                    </ModalBody>
                    <div className="modal-footer">
                        <div className="hstack gap-2 justify-content-end">
                            <Button color="danger"
                                disabled={loading}
                                onClick={() => {
                                    setSaveModel(false)
                                }}
                            >Cancel</Button>
                            {loading ? 
                            <Button color="primary" className="btn-label btn-load" disabled>
                                <i className="ri-tools-fill label-icon align-middle fs-16 me-2"></i>
                                <span className="d-flex align-items-center">
                                    <span className="flex-grow-1 me-2">
                                        Saving...
                                    </span>
                                    <Spinner size="sm" className="flex-shrink-0" role="status"> Saving... </Spinner>
                                </span>
                            </Button> : <Button
                                onClick={() => onSaveReport()}
                                disabled={!reportName}
                                color="primary"
                                className="btn-label">
                                    <i className="ri-tools-fill label-icon align-middle fs-16 me-2"></i>
                                    Save
                            </Button>}
                        </div>
                    </div>
            </Modal>
        </>
    );
};

export default withTranslation()(SaveModel);
