import React from "react";
import { withTranslation } from "react-i18next";
import { Button, Label } from "reactstrap";
import Empty from "./Empty";

interface ValueProps {
    value: any;
    onModifyValue: (property: any, dataSource: any) => void;
    t: (key: string) => string;
}

const Value: React.FC<ValueProps> = ({ t, value, onModifyValue }) => {
    return (
        <div className="d-flex flex-column gap-1">
            <Label className="m-0 fw-bold fs-5">Value</Label>
            {value ? <div className="d-flex flex-column gap-2 overflow-y-scroll">
                <div className="position-relative accordion-item border border-dark-subtle rounded-1">
                    <div className="w-100 p-1 ps-2 m-0 bg-light">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className='d-flex flex-column gap-1'>
                                <div className="text-primary fw-semibold">{value?.label}</div>
                                <div className="text-muted">{value?.data_source?.label}</div>
                            </div>
                            <div className="d-flex">
                                <Button size="sm" color="primary" className="btn btn-ghost-primary waves-effect waves-light p-1" onClick={() => onModifyValue(null, null)}>
                                    <i className="ri-close-line fs-5"></i>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : <Empty message="Add Value field here" />}
        </div>
    )
};

export default withTranslation()(Value);
