import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
    Button,
    Col,
    Offcanvas,
    OffcanvasBody,
    OffcanvasHeader,
    Row,
} from 'reactstrap';
import Select, { components } from 'react-select';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';


const FixError: React.FC<any> = ({ onCloseClick, show, t, record, onUpdateCorrection }: any) => {
    const [correctedRecord, setCorrectedRecord] = useState<any>({})
    const selectDashboardData = createSelector(
        (state: any) => state.Layout,
        (state) => ({
            layoutModeType: state.layoutModeType
        })
    );
    // Inside your component
    const { layoutModeType } = useSelector(selectDashboardData);

    const CustomOption = (props: any) => {
        return (
            <components.Option {...props}>
                <div className={`hstack justify-content-between`}>
                    <span>{props.children}</span>
                    <span>{props.isSelected && <i className="ri-check-line fs-20" />}</span>
                </div>
            </components.Option>
        );
    };

    const customStyles = {
        // Customize the style of the dropdown menu
        control: (provided: any, state: any) => ({
            ...provided,
            width: 200,
            // color: '#495057',
            fontWeight: 900,
            // border: state.isFocused ? '0.5px solid lightgrey' : '0.5px solid lightgrey',
            borderRadius: 3,
            boxShadow: 'none',
            '&:hover': {
                // border: '0.5px solid lightgrey',
            },
            backgroundColor: 'transparent'
        }),
        menu: (provided: any) => ({
            ...provided,
            backgroundColor: layoutModeType === 'light' ? 'white' : '#495057',
            color: layoutModeType === 'light' ? 'black' : 'white',
            borderRadius: 4,
        }),
        // Customize the style of the option in the dropdown menu
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: layoutModeType === 'light' ? 'white' : '#495057',
            color: layoutModeType === 'light' ? 'black' : 'white',
            '&:hover': {
                backgroundColor: layoutModeType === 'light' ? 'white' : '#495057',
            },
        }),
    }

    useEffect(() => {
        setCorrectedRecord(record)
    }, [record])

    const UpdateRecord = (option: any, item: any, index: any) => {
        let newRecord = correctedRecord;
        let newvalidationCorrection = newRecord?.validationCorrection ? newRecord.validationCorrection : [];
        newvalidationCorrection[index] = {
            inValidValue: item.invalidValue,
            validValue: option.value
        }
        setCorrectedRecord({ ...newRecord, validationCorrection: newvalidationCorrection })
    }


    const fieldtype = [
        {
            label: "Text input",
            options: [
                { label: "Single-line text", value: "textfield" },
                { label: "Multi-line text", value: "textarea" },
                { label: "Phone number", value: "phone" },
                { label: "Email", value: "email" },
                { label: "Text Editor", value: "texteditor" },
            ]
        },
        {
            label: "Choosing options",
            options: [
                { label: "Single checkbox", value: "checkbox" },
                { label: "Multiple checkboxes", value: "selectboxes" },
                { label: "Date picker", value: "datetime" },
                { label: "Dropdown select", value: "select" },
                { label: "Suggest dropdown", value: "datasetselect" },
                { label: "Radio select", value: "radio" }
            ]
        },
        {
            label: "values",
            options: [
                { label: "Number", value: "number" }
            ]
        },
        {
            label: "other",
            options: [
                { label: "File", value: "file" },
            ]
        }
    ];

    const renderField = (value: any) => {
        let fieldlabel = ""
        let a = fieldtype.map((field: any) => {
            field && field.options.length && field.options.map((item: any) => {
                if (item.value === value) fieldlabel = item.label;
            })
        })
        return fieldlabel
    }

    return (
        <React.Fragment>
            <Offcanvas
                direction="end"
                isOpen={show}
                id="offcanvasWithBothOptions"
                toggle={() => { onCloseClick(); }}
                className="w-50"
            >
                <OffcanvasHeader className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary" id="offcanvasWithBothOptions" toggle={() => { onCloseClick(); }}>
                    <span className="m-0 me-2 text-white">Fix the Error</span>
                </OffcanvasHeader>
                <OffcanvasBody id="offcanvasWithBothOptions">
                    <Row className='hstack justify-content-between border-bottom-1 bg-primary-subtle p-2'>
                        <Col className='vstack gap-1 align-items-center' md={4} xl={4}>
                            <span>Column</span>
                            <span className='fw-bold fs-16 text-primary'>{record?.columnHeader}</span>
                        </Col>
                        <Col md={4} xl={4} className='vstack align-items-center'>
                            Error
                        </Col>
                        <Col className='vstack gap-1 align-items-center' md={4} xl={4}>
                            <span>Field</span>
                            <span className='fw-bold fs-16 text-primary'>{record?.field.label}</span>
                            <span>{renderField(record?.field.field.type)}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className='p-2 fs-16'>
                                This column has values that don't match the options defined for the {record?.field.label} field. Values that don't match an existing choice won't be imported.
                            </p>
                        </Col>
                    </Row>
                    <Row className=' p-4'>
                        <Col>
                            <table className='align-middle table-nowrap'>
                                <thead className='table-light text-muted border border-1 border-primary bg-primary-subtle'>
                                    <th className='border-bottom-1 p-2 text-center'>
                                        Value From File
                                    </th>
                                    <th className='border-bottom-1 p-2 text-center'>
                                        Replace With
                                    </th>
                                </thead>
                                <tbody className='border border-1 border-primary'>
                                    {record.validationErrors && record.validationErrors && record.validationErrors.length ?
                                        record.validationErrors.map((item: any, index: any) => {
                                            let selectedValue = {}
                                            let options = item.suggestedValues && item.suggestedValues.length ? item.suggestedValues.map((option: any) => {
                                                if (correctedRecord?.validationCorrection?.[index]?.validValue === option) selectedValue = { label: option, value: option };
                                                return { label: option, value: option }
                                            }) : []
                                            return <tr>
                                                <td className='p-2'>
                                                    {item.invalidValue}
                                                </td>
                                                <td className='p-2'>
                                                    <Select
                                                        defaultOptions
                                                        className='form-select-sm'
                                                        components={{ Option: CustomOption }}
                                                        styles={customStyles}
                                                        onChange={(option: any) => UpdateRecord(option, item, index)}
                                                        options={options}
                                                        value={selectedValue}
                                                    ></Select>
                                                </td>
                                            </tr>
                                        })
                                        : null
                                    }
                                </tbody>
                            </table>
                        </Col>
                    </Row>

                </OffcanvasBody>
                <div className="offcanvas-footer bg-primary bg-opacity-10 border-top p-3 justify-content-between hstack gap-2">

                    <Button
                        color="primary"
                        className="btn btn-primary w-auto"
                        onClick={() => { onUpdateCorrection(correctedRecord, correctedRecord.id); onCloseClick(); }}
                    >
                        {t('import.save')}
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    );
};

export default withTranslation()(FixError);
