import { Button, Card, Input, Offcanvas, OffcanvasBody } from "reactstrap";
import { importFile } from './importFile'
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import ImportFlow from "./ImportFlow";
const ImportFrom = (props: any) => {
    const { onCloseClick, importJson, setImportJson } = props;
    const [importFrom, setImportFrom] = useState<any>("");
    const [isimportFlow, setIsImportFlow] = useState(false)

    return (<React.Fragment>
         {isimportFlow && <ImportFlow show={isimportFlow} onCloseClick={() => {setIsImportFlow(false); onCloseClick()}} setImportJson={setImportJson} importJson={importJson} />}          
        <Offcanvas
            direction="end"
            isOpen={true}
            id="offcanvasWithBothOptions"
            toggle={() => { onCloseClick(); }}
            className="w-100"
        >
            <OffcanvasBody id="offcanvasWithBothOptions">
                <div className='vstack align-items-center'>
                    <h4 className='hstack justify-content-center pb-5'>{props.t("import.import_headings")}</h4>
                    <div className='hstack justify-content-center gap-4'>
                        {
                            importFile && importFile.length ? importFile.map((item: any) => {
                                return <Card className={item.value === importFrom ? 'p-4 border border-3 border-primary bg-primary-subtle bg-opacity-10 align-items-between' : 'p-4 border border-1 border-primary border-opacity-25' } style={{ width: 200, height: 300 }} onClick={() => {setImportFrom(item.value); setImportJson({...importJson, ...item})}}>
                                    <div className='hstack justify-content-end'>
                                        <Input type='radio' checked={item.value === importFrom} />
                                    </div>
                                    <div className='vstack gap-2 text-center w-100'>
                                        <div className='align-self-center'>
                                            <img src={item.svg} height={'auto'} width={80} alt='' />
                                        </div>
                                        <h5 className='text-center'>{item.title}</h5>
                                        <p className='fs-12'>
                                            {item.description}
                                        </p>
                                    </div>
                                </Card>
                            }) : null
                        }
                    </div>
                    {/* <div className='vstack gap-2 justify-content-center flex-wrap'>
                        <h6 className='text-center fs-18 fw-semibold'>Need help getting started?</h6>
                        <div className='hstack gap-4 justify-content-center flex-wrap'>
                            <Link to='#' className='fs-18 fw-semibold align-items-center'>View Import guide&nbsp;
                                <FeatherIcon icon="external-link" className="icon-xs icon-dual-primary" />
                            </Link>
                            <Link to='#' className='fs-18 fw-semibold align-items-center'>Download sample spreadsheet&nbsp;
                                <FeatherIcon icon="external-link" className="icon-xs icon-dual-primary" />
                            </Link>
                            <Link to='#' className='fs-18 fw-semibold align-items-center'>View the FAQ&nbsp;
                                <FeatherIcon icon="external-link" className="icon-xs icon-dual-primary" />
                            </Link>
                        </div>
                    </div> */}
                </div>
            </OffcanvasBody>
            <div className="offcanvas-footer bg-primary bg-opacity-10 border-top p-3 justify-content-between hstack gap-2">
                <div className="hstack gap-2">
                    <Link
                        to="#"
                        className="text-danger fw-bold w-auto"
                        onClick={() => { onCloseClick(); }}
                    >
                        {props.t('import.cancel')}
                    </Link>
                </div>
                <Button
                    color="primary"
                    className="btn btn-primary w-auto"
                    disabled={!importFrom}
                    onClick={() => {setIsImportFlow(true);}}>
                    {props.t('import.next')}
                </Button>
            </div>
        </Offcanvas>
    </React.Fragment>)
}        

export default withTranslation()(ImportFrom);
