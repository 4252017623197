import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Label, Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { createSelector, ThunkDispatch } from '@reduxjs/toolkit';
import { Action } from 'redux';
import { tablesGetRequest } from 'store/report/action';
import { Table } from 'store/report/types';

interface DataSourceDrawerProps {
    isOpen: boolean;
    initData: any;
    toggle: (value: boolean) => void;
    onDataSource: (primary: any, secondary: any) => void;
    t: (key: string) => string;
}

const DataSourceDrawer: React.FC<DataSourceDrawerProps> = ({
    isOpen,
    toggle,
    onDataSource,
    initData,
    t
}) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const user = useSelector((state: ApplicationState) => state.auth.userProfile)
    const tables = useSelector((state: ApplicationState) => state.report.tables)
    const tablesLoading = useSelector((state: ApplicationState) => state.report.tables_loading)
    
    const [models, setModels] = useState<any[]>([])
    const [secondaryModels, setSecondaryModels] = useState<any[]>([])
    const [selectedPrimarySource, setSelectedPrimarySource] = useState<any>(null)
    const [selectedSecondarySources, setSelectedSecondarySources] = useState<any>([])

    
    const selectDashboardData = createSelector(
        (state: any) => state.Layout,
        (state) => ({
            layoutModeType: state.layoutModeType
        })
    );
    // Inside your component
    const { layoutModeType } = useSelector(selectDashboardData);

    useEffect(() => {
        if(user?.id) {
            const data = {
                "tables": [],
                "filters": {
                    "primary_keys": true,
                    "foreign_keys": true,
                    "metadata_types": ["datasetselect", "treeselect"]
                }
            }
            dispatch(tablesGetRequest(data))
        }
    }, [user, dispatch]);

    useEffect(() => {
        if(initData?.length) {
            const primary = initData.filter((dataSource: any) => dataSource?.primary)
            const secondary = initData.filter((dataSource: any) => !dataSource?.primary)
            setSelectedPrimarySource(primary)
            setSelectedSecondarySources(secondary)
        }
    }, [initData]);

    useEffect(() => {
        if(tables?.length) {
            const models = tables.map((item: Table) => {
                return {
                    label: item.table_name.charAt(0).toUpperCase() + item.table_name.slice(1),
                    value: item.table_name,
                    schema: item?.schema_name,
                    type: item?.type,
                    additional: item,

                }
            })
            setModels(models)
            if(selectedPrimarySource) {
                const secondaryModels = models.filter((model: any) => model.value !== selectedPrimarySource.value)
                setSecondaryModels(secondaryModels)
            }
        }
    }, [tables, user, selectedPrimarySource])


    const onSelectedPrimarySource = (selectedModel: any) => {
        setSelectedPrimarySource(selectedModel)
        const secondaryModels = models.filter((model: any) => model.value !== selectedModel.value)
        setSecondaryModels(secondaryModels)
        setSelectedSecondarySources([])
    }

    const onAdd = () => {
        onDataSource(selectedPrimarySource, selectedSecondarySources)
        resetValues()
        toggle(false)
    }
    const resetValues = () => {
        setSecondaryModels([])
        setSelectedPrimarySource(null)
        setSelectedSecondarySources([])
    }
    if(tablesLoading) {
        return (
            <Offcanvas
                direction="end"
                isOpen={isOpen}
                backdrop={false}
                toggle={() => toggle(false)}
                className="placeholder-glow"
            >
                <OffcanvasHeader
                    className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary"
                    id="processFlowCreateHeader"
                    toggle={() => toggle(false)}
                >
                    <span className="m-0 me-2 text-white">
                        {t('reports.add.heading')}
                    </span>
                </OffcanvasHeader>
                <OffcanvasBody className="bg-light" id="processFlowCreateBody">
                    <div className="d-flex flex-column gap-4">
                        <div className="d-flex flex-column gap-1">
                            <Label className="fw-bold placeholder">{t('reports.add.primary_data_source')}</Label>
                            <p className="placeholder w-100 mb-0"></p>
                            <p className="placeholder w-50 mb-1"></p>
                            <div className="placeholder w-100" style={{height: '36px'}}></div>
                        </div>
                        <div className="d-flex flex-column gap-1">
                            <Label className='fw-bold placeholder'>{t('reports.add.secondary_data_sources')}</Label>
                            <p className="placeholder w-100 mb-0"></p>
                            <p className="placeholder w-50 mb-1"></p>
                            <div className="placeholder w-100" style={{height: '36px'}}></div>
                        </div>
                    </div>
                </OffcanvasBody>
                <div className="offcanvas-footer bg-info bg-opacity-10 border-top p-3 justify-content-between hstack gap-2">
                    <Link
                        to="#"
                        className="text-danger fw-bold w-auto placeholder"
                    >
                        {t('reports.add.btn_cancel')}
                    </Link>
                    <div className="placeholder" style={{height: '36px', width: '80px'}}>{t('reports.add.btn_add')}</div>
                </div>
            </Offcanvas>
        );
    }

    const customStyles = {
        // Customize the style of the dropdown menu
        input: (provided: any) => ({
            ...provided,
            color: layoutModeType === 'light'? 'black' : 'white',
          }),
        control: (provided: any, state: any) => ({
                    ...provided,
                    backgroundColor: 'transparent'
        }),
        menu: (provided: any) => ({
          ...provided,
          backgroundColor: layoutModeType === 'light'? 'white' : '#495057',
          color: layoutModeType === 'light'? 'black' : 'white',
        }),
        // Customize the style of the option in the dropdown menu
        option: (provided: any, state: any) => ({
          ...provided,
          backgroundColor: layoutModeType === 'light'? 'white' : '#495057',
          color: layoutModeType === 'light'? 'black' : 'white',
          '&:hover': {
            backgroundColor: layoutModeType === 'light' ? 'white' : '#495057',
          },
        }),
    }

    return (
        <Offcanvas
            direction="end"
            isOpen={isOpen}
            backdrop={false}
            toggle={() => toggle(false)}
        >
            <OffcanvasHeader
                className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary"
                id="processFlowCreateHeader"
                toggle={() => toggle(false)}
            >
                <span className="m-0 me-2 text-white">
                    {t('reports.add.heading')}
                </span>
            </OffcanvasHeader>
            <OffcanvasBody className="bg-light" id="processFlowCreateBody">
                <div className="d-flex flex-column gap-4">
                    <div>
                        <Label className="fw-bold">{t('reports.add.primary_data_source')}</Label>
                        <p>{t('reports.add.primary_data_source_helper')}</p>
                        <Select
                            isSearchable
                            name="values"
                            options={models}
                            styles={customStyles}
                            onChange={onSelectedPrimarySource}
                            value={selectedPrimarySource}
                        />
                    </div>
                    <div>
                        <Label className='fw-bold'>{t('reports.add.secondary_data_sources')}</Label>
                        <p>{t('reports.add.secondary_data_sources_helper')}</p>
                        <Select
                            closeMenuOnSelect={false}
                            isMulti
                            isClearable
                            isSearchable
                            name="values"
                            options={secondaryModels}
                            styles={customStyles}
                            onChange={setSelectedSecondarySources}
                            value={selectedSecondarySources}
                        />
                    </div>
                </div>
            </OffcanvasBody>
            <div className="offcanvas-footer bg-info bg-opacity-10 border-top p-3 justify-content-between hstack gap-2">
                <Link
                    to="#"
                    className="text-danger fw-bold w-auto"
                    onClick={() => toggle(false)}
                >
                    {t('reports.add.btn_cancel')}
                </Link>
                <Button
                    color="primary"
                    className="btn btn-primary w-auto"
                    onClick={onAdd}
                >
                    {t('reports.add.btn_add')}
                </Button>
            </div>
        </Offcanvas>
    );
};

export default withTranslation()(DataSourceDrawer);
