import React from "react";
import { withTranslation } from "react-i18next";

const ChartLoader: React.FC = () => {
    return (
        <div className="chart-placeholder">
            <div className="graph graph1"></div>
            <div className="graph graph2"></div>
            <div className="graph graph3"></div>
            <div className="graph graph4"></div>
            <div className="graph graph5"></div>
        </div>
    );
};

export default withTranslation()(ChartLoader);
