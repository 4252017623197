import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
} from "reactstrap";
import { ApplicationState } from "store";
import { dealCreateRequest, dealListGetRequest, dealsGetRequest } from "store/deals/action";
import { productGetRequest, productListGetRequest, productsMetadataGetRequest} from "store/products/action";
import ProductInfoEdit from "./ProductInfoEdit";
import { isJson } from "utils";
import { useParams } from "react-router-dom";

interface FiltersData {
    filters: any[];
    sorts: any[];
}

const DealCreate: React.FC<any> = ({ show, onCloseClick, props, parentField, setDisabledBtn, disabledBtn }: any) => {
    const { id } = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const metadata = useSelector((state: ApplicationState) => state.products.metaDataFields);
    const products = useSelector((state: ApplicationState) => state.products.list);
    const productDetails: any = useSelector((state: ApplicationState) => state.products.details);
    const [options, setOptions] = useState<any>([])
    const [fields, setFields] = useState([]) 
    const [product, setProductDetails] = useState<any>(null) 
    const [productid, setproductId] = useState<any>(null)
   

    useEffect(() => {
        dispatch(productsMetadataGetRequest({ PageSize: 100 }))
        dispatch(productListGetRequest())
        setProductDetails(null)
        setproductId(null)
    }, [])

    useEffect(() => {
        if(productid) {
            setProductDetails(productDetails);
        }
        else {
            setProductDetails(null);
        }
    }, [productDetails])

    useEffect(() => {
        let newoptions: any = []
        products?.length && products.map((item: any) => {
            newoptions = [...newoptions, { label: item.valuesJson.name, value: item.id }]
        })
        setOptions(newoptions)
    }, [products])

    const handleProductSelect = (option: any) => {
        setproductId(option)
        dispatch(productGetRequest(option.value))
    }

    useEffect(() => {
        let fields: any = []
        metadata && metadata.length && metadata.map((field: any) => {
            let fieldvaluesJson = field && field.valuesJson && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : field?.valuesJson ? field.valuesJson : {}
            if (fieldvaluesJson.quickCreate) {
                fields.push(field)
            }
        })
        setFields(fields)
    }, [metadata])

    const OnAddEntry = (formValues: any) => {
        setDisabledBtn(true)
        const handleSuccess = (body: any) => {
            setProductDetails(null)    
            setproductId(null)    
            const filtersData: any = {
                "filters": [
                    {
                        quick: [],
                        advance: [], 
                        search: null,
                        parent:  [{
                            "key": parentField?.key,
                            "keyLabel": parentField?.label,
                            "condition": "IN",
                            "conditionLabel": "is any of",
                            "values": [id],
                            "valuesLabel": [],
                            "property": parentField,
                            "quick": true,
                        }]
                    }
                ],
                "sorts": [
                    {
                        "field": "createdAt",
                        "order": "desc"
                    }
                ]
            }
            dispatch(dealListGetRequest(filtersData, 0 , 50));
            onCloseClick()
        }
        const data = { products: product.id, students: id, amount: formValues?.amount, currency: formValues?.currency, description: formValues?.description }
            // students: id,
            // products: { ...formValues, id: product.id },
        // }
        dispatch(dealCreateRequest(data, handleSuccess, () => { }))
        setDisabledBtn(false)
    }
    return (<>
        <Offcanvas
            direction="end"
            isOpen={show}
            id="offcanvasExample"
            toggle={onCloseClick}
            backdrop={false}
        >
            <OffcanvasHeader className="bg-light" toggle={() => { onCloseClick(!show) }}>
                {props.t("student.add_new_entry")}
            </OffcanvasHeader>
            <OffcanvasBody>
                <div className="my-2">
                    <Select
                        className="fs-16 fw-medium"
                        defaultOptions
                        placeholder={props.t("deal.select_product")}
                        value={productid}
                        onChange={(option: any) => handleProductSelect(option)}
                        options={options}
                        name="choices-single-default"
                    ></Select>
                </div>
                <div className="my-2">
                    <ProductInfoEdit OnAddEntry={OnAddEntry} fields={fields} product={product} disabledBtn={disabledBtn} />
                </div>
            </OffcanvasBody>
        </Offcanvas>
    </>
    );
};

export default DealCreate;
