import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { importModel } from './importModel';
import { Card, Input } from 'reactstrap';
import FeatherIcon from 'feather-icons-react';


const Model = (props: any) => {   
    const [ selectedCard, setselectedCrad] = useState<any>(""); 
    const {  setImportJson, importJson, setModal } = props;
    return (
        <React.Fragment>
            <div className='vstack justify-content-center gap-3'>
                <h3 className='text-center'>What kind of data is in your file?</h3>
                <div className='hstack justify-content-center gap-3'>
                {
                    importModel && importModel.length ? importModel.map((item: any) => {
                        return <Card className={item.model === selectedCard ? 'p-4 border  border-3 border-primary bg-primary-subtle bg-opacity-10 gap-2' : 'p-4 border border-1 border-primary border-opacity-25 gap-2'} style={{ width: 180, height: 250 }} onClick={() =>{setselectedCrad(item.model); setModal(item.model); setImportJson({...importJson, model: item.model})} }>
                            <div className='hstack justify-content-end'>
                                <Input type='checkbox' checked={item.model === selectedCard} />
                            </div>
                            <div className='align-self-center'>
                                <i className={`${item.svg} fs-24 text-primary`}></i>
                            </div>
                            <div className='vstack gap-2'>
                                <h5 className='text-center'>{item.title}</h5>
                            </div>
                            <p className='fs-14 text-center'>
                                {item.description}
                            </p>
                        </Card>
                    }) : null
                }
                </div>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Model);
