import React, { Fragment } from "react";
import {
    useTable,
    useGlobalFilter,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
    useRowSelect
} from "react-table";
import { withTranslation } from 'react-i18next';
import { Table } from "reactstrap";

interface TableContainerProps {
    columns?: any;
    data?: any;
    tableClass?: string,
    theadClass?: string,
    thClass?: string,
}

const TableContainer = ({
    columns,
    data,
    tableClass,
    theadClass,
    thClass,
}: TableContainerProps) => {
    const {
        getTableProps,
        headerGroups,
        page,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0, pageSize: 500
            },
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );
    return (
        <div>
            <Table responsive={true} hover {...getTableProps()} className={tableClass +'m-0'}>
                <thead  className={theadClass}>
                    {headerGroups && headerGroups.length ? headerGroups.map((headerGroup: any) => (
                        <tr key={headerGroup.id}  {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                <th key={column.id} className={thClass + " fw-bold"}
                                    {...column.isSortable ? { ...column.getSortByToggleProps() } : ""}

                                >
                                    <div title={column.Header}>
                                        {column.render("Header")}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    )) : null}
                </thead>
                <tbody>
                {page.map((row: any, index: any) => {
                    prepareRow(row);
                    return (
                            <Fragment key={row.getRowProps().key}>
                                <tr style={{ verticalAlign: 'middle', maxHeight: 50 }}>
                                    {row.cells.map((cell: any) => {
                                        return <td key={cell.id} style={{ verticalAlign: 'middle', minWidth:100 }} className='align-items-center justify-content-between' {...cell.getCellProps()}>
                                            {cell.render("Cell")}
                                        </td>
                                    })}  
                                </tr>
                            </Fragment>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
};

export default withTranslation()(TableContainer);