import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row, Label, Table, Input } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import { useDispatch, useSelector } from "react-redux";
import { confirmRecommendations, getAuthorizationFormDetails } from "store/recommendation/action";
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { ToastContainer, toast } from 'react-toastify';
import RecommendSuccess from './StudentForm';
import { getRecommendationsDetails } from '../../store/recommendation/action'
import { ApplicationState } from 'store';

interface Inputs {
    password: string;
    confirm_password: string;
}

interface ResetPasswordProps {
    t: (key: string) => string;
}

const AgentChangeForm: React.FC<ResetPasswordProps> = ({ t }) => {
    const { tenant, id } = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const recommendDetails = useSelector((state: ApplicationState) => state.recommend.details);
    const [name, setName] = useState<any>(null)
    const [companyName, setCompanyName] = useState<any>(null)
    const [confirmedList, setConfirmed] = useState<any>([]);
    const [selectedList, setSelectedList] = useState<any>([]);
    const [linktrue, setLinktrue] = useState<boolean>(false);
    const [isConfirm, setIsConfirm] = useState<boolean>(false);

    const onSubmit = () => {
        const course_name = confirmedList.join(",")
        const data = {
            application_ids: course_name
        }
        const handleSuccess = (body: any): void => {
            setIsConfirm(true)
        }
        const handleError = (body: any): void => {
            setIsConfirm(true)
        }
        dispatch(confirmRecommendations(tenant, id, data, handleSuccess, handleError));
    }

    const handleselect = (obj: any, value: boolean) => {
        if (value) {
            let newList = confirmedList;
            newList.push(obj.id)
            setLinktrue(true)
            setConfirmed(newList)
        }
        else {
            let newList = confirmedList.filter((item: any) => item !== obj.id);
            if (!newList.length) {
                setLinktrue(false)
            }
            setConfirmed(newList)
        }
    }

    const handleIconClick = (url: any) => {
        window.open(url, '_blank');
    };

    useEffect(() => {
        const handleSuccess = () => { }
        const handlefailure = () => { }
        dispatch(getAuthorizationFormDetails("205a6c0a805c4a90bb4d2abbc17ba1ff", "applications", "37b5fed8-def8-4c5e-b9c5-fb3f50b8db6f", handleSuccess, handlefailure))
    }, [])


    useEffect(() => {
        const parsedData: any = recommendDetails && recommendDetails.length ? recommendDetails.map((item: any) => {
            const valuesJson = item.valuesJson ? item.valuesJson : {};
            const student = valuesJson?.student
            const course = valuesJson?.courses;
            const university = valuesJson?.institutes;
            let values = { id: item.id, createdAt: item.createdAt, company_name: item.companyName, course: course, university: university }
            setName(student?.name)
            setCompanyName(item?.companyName)
            return values
        }) : []
        let courses = parsedData && parsedData.length && parsedData
        setSelectedList(courses)
    }, [recommendDetails])

    return (
        <Container fluid>
            <ToastContainer />
            {
                isConfirm ?
                    <RecommendSuccess />
                    :
                    <Row className="hstack justify-content-center">
                        <Col xs={12} sm={12} lg={10} md={10} xl={10} className="hstack justify-content-center">
                            <Row className="hstack justify-content-center">
                                <Col xs={12} sm={12} lg={10} md={10} xl={10}>
                                    <Card>
                                        <div className="bg-info-subtle position-relative">
                                            <CardBody className="card-body p-5">
                                                <div className="text-center">
                                                    <h3>Courses Recommendations</h3>
                                                </div>
                                            </CardBody>
                                            <div className="shape">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    version="1.1"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    xmlns-svgjs="http://svgjs.com/svgjs"
                                                    width="1440"
                                                    height="60"
                                                    preserveAspectRatio="none"
                                                    viewBox="0 0 1440 60"
                                                >
                                                    <g mask='url("#SvgjsMask1001")' fill="none">
                                                        <path
                                                            d="M 0,4 C 144,13 432,48 720,49 C 1008,50 1296,17 1440,9L1440 60L0 60z"
                                                            style={{ fill: "var(--vz-secondary-bg)" }}
                                                        ></path>
                                                    </g>
                                                    <defs>
                                                        <mask id="SvgjsMask1001">
                                                            <rect width="1440" height="60" fill="#ffffff"></rect>
                                                        </mask>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                        <CardBody className="card-body p-5">
                                            <Table className="table-bordered align-middle mb-0">
                                    {/* <tbody>
                                        <tr>
                                            <td style={{ width: "40%", whiteSpace: "nowrap" }}>First Name</td>
                                            <td style={{ width: "60%" }}>{dataReceived?.details?.valuesJson?.student?.first_name}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "40%", whiteSpace: "nowrap" }}>Last Name</td>
                                            <td style={{ width: "60%" }}>{dataReceived?.details?.valuesJson?.student?.last_name}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "40%", whiteSpace: "nowrap" }}>Date of Birth:</td>
                                            <td style={{ width: "60%" }}>{dataReceived?.details?.valuesJson?.student?.date_of_birth ? moment(dataReceived?.details?.valuesJson?.student?.date_of_birth).format("DD/MMM/YYYY") : ""}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "40%", whiteSpace: "nowrap" }}>Country of Nationality:</td>
                                            <td style={{ width: "60%" }}>{dataReceived?.details?.valuesJson?.student?.nationality}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "40%", whiteSpace: "nowrap" }}>University application reference Number/UCAS ID</td>
                                            <td style={{ width: "60%" }}>Maritza Blanda</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "40%", whiteSpace: "nowrap" }}>Course Applied</td>
                                            <td style={{ width: "60%" }}>{dataReceived?.details?.valuesJson?.courses?.name}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "40%", whiteSpace: "nowrap" }}>Date Application Submitted</td>
                                            <td style={{ width: "60%" }}>
                                                {dataReceived?.details?.createdAt ? moment(dataReceived.details?.createdAt).format("DD/MMM/YYYY") : ""}
                                            </td>

                                        </tr>
                                        <tr>
                                            <td style={{ width: "40%", whiteSpace: "nowrap" }}>Your Agency</td>
                                            <td style={{ width: "60%" }}>Maritza Blanda</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "40%", whiteSpace: "nowrap" }}>Reason(s) for taking assistance:</td>
                                            <td style={{ width: "60%" }}>
                                                <Input />
                                            </td>
                                        </tr>
                                    </tbody> */}
                                </Table>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
            }
        </Container>
    );
};

export default withTranslation()(AgentChangeForm);