import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Accordion, AccordionBody, Button, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, Label, UncontrolledDropdown } from "reactstrap";
import { frequencies } from "common/data/frequencies";
import Empty from "./Empty";

interface XAxisProps {
    xAxis: any;
    onModifyXAxis: (property: any, dataSource: any) => void;
    setXAxis: (xAxis: any) => void;
    t: (key: string) => string;
}

const XAxis: React.FC<XAxisProps> = ({ t, xAxis, onModifyXAxis, setXAxis }) => {
    const [open, setOpen] = useState('');
    const [frequency, setFrequency]: any = useState(frequencies[0]);
    const onSetFrequency = () => {
        setXAxis({...xAxis, frequency: frequency})
        setOpen('')
    }
    return (
        <div className="d-flex flex-column gap-1">
            <Label className="m-0 fw-bold fs-5">X-Axis</Label>
            {xAxis ? <Accordion className="d-flex flex-column gap-2 h-100" open={open} toggle={setOpen}>
                <div className="d-flex flex-column gap-2 overflow-y-scroll">
                    <div className="position-relative accordion-item border border-dark-subtle rounded-1">
                        <div className="w-100 p-1 ps-2 m-0 bg-light">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className='d-flex flex-column gap-1'>
                                    <div className="text-primary fw-semibold">{xAxis?.label}</div>
                                    <div className='text-muted'>{xAxis?.data_source?.label}</div>
                                </div>
                                <div className="d-flex">
                                    {xAxis?.type === "datetime" ? <Button size="sm" color="primary" className="btn btn-ghost-primary waves-effect waves-light p-1"  onClick={() => setOpen("1")}>
                                        <i className="ri-edit-2-fill fs-5"></i>
                                    </Button> : null}
                                    <Button size="sm" color="primary" className="btn btn-ghost-primary waves-effect waves-light p-1" onClick={() => onModifyXAxis(null, null)}>
                                        <i className="ri-close-line fs-5"></i>
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {xAxis?.type === "datetime" ? <AccordionBody accordionId={"1"}>
                            {open === "1" ? <div className='d-flex flex-column align-items-center justify-content-start gap-4'>
                                <div className='d-flex flex-column w-100 gap-4'>
                                    <div className='d-flex flex-column w-100 gap-1'>
                                        <Label className="m-0 fw-semibold fs-6">Frequency:</Label>
                                        <ButtonGroup>
                                            <UncontrolledDropdown className='w-100'>
                                                <DropdownToggle
                                                    tag="button"
                                                    className="btn btn-soft-dark text-left d-flex align-items-center justify-content-between w-100"
                                                >
                                                    {frequency.label} <i className="mdi mdi-chevron-down"></i>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdownmenu-primary">
                                                    {frequencies.map((frequency) => {
                                                        return (
                                                            <DropdownItem
                                                                onClick={() => setFrequency(frequency)}
                                                            >
                                                                {frequency.label}
                                                            </DropdownItem>
                                                        )
                                                    })}
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </ButtonGroup>
                                    </div>
                                    <div className='d-flex gap-2 justify-content-start w-100'>
                                        <Button
                                            onClick={() => onSetFrequency()}
                                            color="primary"
                                        >
                                            Apply
                                        </Button>
                                        <Button color="light"
                                            onClick={() => setOpen("")}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </div>
                            </div> : null}
                        </AccordionBody> : null }
                    </div>
                </div>
            </Accordion> : <Empty message="Add X-Axis field here" />}
        </div>
    )
};

export default withTranslation()(XAxis);
