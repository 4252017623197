import {BanksActionTypes, Banks} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";
import { createQueryLink, responseHandler } from "utils";

const { api } = config;
const DTS_API_STUDENT = api.DTS_API_STUDENT

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const bankGetRequest: AppThunk = (id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: BanksActionTypes.GET_BANK_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/models/banks/${id}`, {
                method: "GET",
                headers: headers
            });
            await responseHandler(response, dispatch, BanksActionTypes.GET_BANK_SUCCESS, BanksActionTypes.GET_BANK_ERROR, null, null, null, "GET");
           
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

export const banksMetadataGetRequest: AppThunk = (filterObject) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: BanksActionTypes.GET_BANKMETADATA_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        headers.append('Properties-Model', "banks");
        const searchQuery =   createQueryLink(filterObject)  
        try {  
            const response = await fetch(searchQuery, {
                method: "GET",
                headers: headers
            });    
            await responseHandler(response, dispatch, BanksActionTypes.GET_BANKMETADATA_SUCCESS, BanksActionTypes.GET_BANKMETADATA_ERROR, null, null, null, "GET");
           
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

export const bankUpdateRequest: AppThunk = (id, data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: BanksActionTypes.PUT_BANK_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_STUDENT}/models/banks/${id}`,
            {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(data)
            }
        );                
        const error = {
            204: {
                "message": "Bank updated Successfully",
                "success": true
            },
            200: {
                "message": "Bank updated Successfully",
                "success": true
            }
        }  
        await responseHandler(response, dispatch, BanksActionTypes.PUT_BANK_SUCCESS, BanksActionTypes.PUT_BANK_ERROR, error, handleSuccess, handleError);
   };
};

export const bankDelete: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: BanksActionTypes.DELETE_BANK_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_STUDENT}/models/banks/${id}`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(id)
        });
        try {
            const error = {
                204: {
                    "message": "Bank deleted Successfully",
                    "success": true
                },
                200: {
                    "message": "Bank deleted Successfully",
                    "success": true
                }
            }  
            await responseHandler(response, dispatch, BanksActionTypes.DELETE_BANK_SUCCESS, BanksActionTypes.DELETE_BANK_ERROR, error, handleSuccess, handleError);
       } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: BanksActionTypes.DELETE_BANK_ERROR
            });
            handleSuccess();
        }
    };
};

export const bankListGetRequest: AppThunk = (data, page, size, signal) => {
    let requestbody = data ? data : {}
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: BanksActionTypes.GET_BANKS_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }
        const response = await fetch(`${DTS_API_STUDENT}/models/get/banks?page=${page?page:0}&pageSize=${size?size:50}`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(requestbody),
            signal
        }).catch((error) => {
            return false;
        });
        if(typeof response === 'object' && !Array.isArray(response) && response !== null) {
            const body = await response?.json();
            const {response: response_1, body: body_1} = {response, body};
            if (!response_1.ok) {
                dispatch({
                    type: BanksActionTypes.GET_BANKS_ERROR,
                    payload: body_1
                });
            } else {
                dispatch({
                    type: BanksActionTypes.GET_BANKS_SUCCESS,
                    payload: body_1
                });
            }
        }
    };
};

export const bankCreateRequest: AppThunk = (data, handleSuccess, handleError) => {
  return async (dispatch: Dispatch, getState: any): Promise<void> => {
    const { auth: { token } } = getState();
    dispatch({ type: BanksActionTypes.POST_BANK_REQUEST });
    try {
      const response = await fetch(
        `${DTS_API_STUDENT}/models/banks`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "token": token
          },
          body: JSON.stringify(data)
        }
      );
           
      const error = {
        208: {
            "message": "Bank already exist",
            "success": false
        },
        201: {
            "message": "Bank created Successfully",
            "success": true
        },
        200: {
            "message": "Bank created Successfully",
            "success": true
        }
    }
    await responseHandler(response, dispatch, BanksActionTypes.POST_BANK_SUCCESS, BanksActionTypes.POST_BANK_ERROR, error, handleSuccess, handleError);

    } catch (error) {
      dispatch({ type: BanksActionTypes.POST_BANK_ERROR, payload: error });
      handleError();
    }
  };
};




