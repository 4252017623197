import React from "react";
import { withTranslation } from "react-i18next";
import Fields from "./common/Fields";
import Columns from "./common/Columns";

interface TableConfigOptionsProps {
    columns: any;
    fields: any;
    moveColumns: (dragIndex: number, hoverIndex: number, isDragging: boolean) => void
    onColumnUpdated: (newfilter: any) => void
    onColumnDelete: (deleteColumn: any) => void
    moveFields: (dragIndex: number, hoverIndex: number, isDragging: boolean) => void
    onFieldUpdated: (newfilter: any) => void
    onFieldDelete: (deleteColumn: any) => void
    t: (key: string) => string;
}

const TableConfigOptions: React.FC<TableConfigOptionsProps> = ({
    columns,
    fields,
    moveColumns,
    onColumnUpdated,
    onColumnDelete,
    moveFields,
    onFieldUpdated,
    onFieldDelete
}) => {
    return (
        <div className="d-flex flex-column gap-4">
            <Columns
                columns={columns}
                moveColumns={moveColumns}
                onColumnUpdated={onColumnUpdated}
                onColumnDelete={onColumnDelete}
            />
            <Fields
                fields={fields}
                moveFields={moveFields}
                onFieldUpdated={onFieldUpdated}
                onFieldDelete={onFieldDelete}
            />
        </div>
    )
};

export default withTranslation()(TableConfigOptions);
