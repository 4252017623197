import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
    Button,
    Col,
    Nav,
    NavItem,
    Offcanvas,
    OffcanvasBody,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Input,
} from 'reactstrap';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import ImportModal from './ImportModal';
import Upload from './Upload';
import Mapping from './Mapping';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { ApplicationState } from 'store';
import config from "../../../../config";
import { importProcessRequest } from 'store/import/action';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
const { DTS_API_STUDENT } = config.api;


const ImportFlow = (props: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch(); 
    
    const { onCloseClick, show, t, setImportJson, importJson} = props;
    const access_token = useSelector((state: ApplicationState) => state.auth?.token)
    const [activeArrowTab, setactiveArrowTab] = useState<number>(1);
    const [passedarrowSteps, setPassedarrowSteps] = useState<number[]>([1]);
    const [selectedFiles, setselectedFiles] = useState<any>([]);
    const [disabled, setDisabled] = useState<any>([]);
    const [modal, setModal] = useState<any>('');
    const [mappings, setMappings] = useState<any>('');
    const [loading, setLoading] = useState<any>(false);
    const [finalMappings, setFinalMappings] = useState<any>([]);
    const [unMappedCount, setUnMappedCount] = useState<any>();
    const [extra, setExtra] = useState<any>({})

    const toggleArrowTab = (tab: number) => {
        if (activeArrowTab !== tab) {
            const modifiedSteps = [...passedarrowSteps, tab];
            if (tab >= 1 && tab <= 4) {
                setactiveArrowTab(tab);
                setPassedarrowSteps(modifiedSteps);
            }
        }
    };

    const uploadFile = async () => {
        const file = selectedFiles && selectedFiles.length ? selectedFiles[0] : null;
            if (file) {
                const formData = new FormData();
                formData.append('file', file);
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        "token": access_token
                    },
                }
                try {
                    await axios.post(`${DTS_API_STUDENT}/import/upload?model=${modal}`, formData, config)
                    .then((res: any) => { 
                        if(res?.mappings)   {
                            setMappings(res.mappings)    
                            setLoading(false)  
                        }   
                        else {
                            const message = () => toast(`An unexpected error occurred, please check uploaded file and try again later`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
                            message();                     
                            setLoading(false)  
                        }           
                    })                    
                    toggleArrowTab(activeArrowTab + 1);
                }
                catch (error) {
                    console.error("Error downloading file:", error);
                    const message = () => toast(`An unexpected error occurred, please check file you are trying to upload and try again.`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
                    message();                     
                    setLoading(false)  
                }
            }
    }

    const finalMappingsRequest = () => {
        const handleSuccess = () => {       
            setImportJson({})
            onCloseClick()            
            setLoading(false)
        }
        const handleFailure = () => {
            setLoading(false)
            const message = () => toast(`An unexpected error occurred, please check file you are trying to upload and try again.`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
            message(); 
        }
        dispatch(importProcessRequest({mappings:finalMappings, extra:extra}, handleSuccess, handleFailure))
    }

    const nextClicked = (value: any) => {
        switch(value) {
            case 1: 
                toggleArrowTab(activeArrowTab + 1);
                setDisabled(!importJson.model)
                break;
            case 2: 
                setLoading(true)
                uploadFile();  
                setDisabled(!selectedFiles.length)
                break;
            case 3: 
                setLoading(true)
                finalMappingsRequest()           
                break;
        }
        
    };

    const backClicked = () => {
        toggleArrowTab(activeArrowTab - 1);
    };

    const importUnmapped = (value: any) => {
        setExtra({...extra, importUnmappedData:value});
    }

    useEffect(() => {
        // Re-render logic or no need to add any logic
        switch(activeArrowTab) {
            case 1: 
                setDisabled(!importJson.model)
                break;
            case 2: 
                setDisabled(!selectedFiles.length)
                break;
            case 3:         
                break;
        }
      }, [activeArrowTab, importJson, selectedFiles]);

    return (
        <React.Fragment>
            <Offcanvas
                direction="end"
                isOpen={show}
                id="offcanvasWithBothOptions"
                toggle={() => {onCloseClick();}}
                className="w-100"
            >
                <OffcanvasBody id="offcanvasWithBothOptions">
                    <div className="hstack step-arrow-nav mb-4">
                        <Row className="w-100 hstack justify-content-center">
                            <Col lg={8} className="hstack justify-content-between">
                                <Nav
                                    className="w-100 nav-pills custom-nav nav-justified"
                                    role="tablist"
                                >
                                    <NavItem>
                                        <NavLink
                                            href="#"
                                            id="basic-info"
                                            className={classnames({
                                                active: activeArrowTab === 1,
                                                done: activeArrowTab <= 2 && activeArrowTab > 0,
                                            })}
                                        >
                                            {t('import.type')}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            href="#"
                                            id="field-type"
                                            className={classnames({
                                                active: activeArrowTab === 2,
                                                done: activeArrowTab <= 3 && activeArrowTab > 1,
                                            })}
                                        >
                                             {t('import.upload')}                                            
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            href="#"
                                            id="rules"
                                            className={classnames({
                                                active: activeArrowTab === 3,
                                                done: activeArrowTab <= 4 && activeArrowTab > 2,
                                            })}
                                        >
                                            {t('import.map')}
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>
                    </div>
                    <TabContent activeTab={activeArrowTab}>
                        <TabPane id="basic-info" tabId={1}>
                            <Row className="hstack justify-content-center">
                                <Col lg={5}>
                                    <div className="hstack justify-content-center">
                                       <ImportModal setImportJson={setImportJson} importJson={importJson} setModal={setModal} modal={modal} />
                                    </div>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane id="field-type" tabId={2}>
                            <Row className="hstack justify-content-center">
                                <Col lg={10}>
                                   <Upload selectedFiles={selectedFiles} setselectedFiles={setselectedFiles} setImportJson={setImportJson} importJson={importJson} />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane id="rules" tabId={3}>
                            <Row className="hstack justify-content-center">
                                <Col lg={8}>
                                    <Mapping mappings={mappings} loading={loading} setFinalMappings={setFinalMappings} setImportJson={setImportJson} importJson={importJson} setUnMappedCount={setUnMappedCount} />
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                </OffcanvasBody>
                <div className="offcanvas-footer bg-primary-subtle bg-opacity-10 border-top p-3 justify-content-between hstack gap-2">
                    <div className="hstack gap-2">
                        {activeArrowTab > 1 && activeArrowTab <= 3 ? (
                            <Button
                                color="primary"
                                className="w-auto"
                                onClick={() => backClicked()}
                                outline={true}
                            >
                                {t('import.back')}
                            </Button>
                        ) : null}
                        <Link
                            to="#"
                            className="text-danger fw-bold w-auto"
                            onClick={() => {onCloseClick(); setactiveArrowTab(1); setImportJson({})}}
                        >
                            {t('import.cancel')}
                        </Link>
                    </div>
                    
                    <div className='hstack gap-2'>
                    {activeArrowTab === 3 ?
                        <div className='vstack cursor-pointer'>
                            <h6 className='fw-bold'>You have {unMappedCount} unmapped column.</h6>
                            <div className='hstack'>
                                <Input type='checkbox' checked={extra.importUnmappedData} onClick={(e: any) => importUnmapped(e.target.checked) }/>
                                <div>Don't import data in ummapped column</div>
                            </div>
                        </div> : null}
                        <Button
                            color="primary"
                            className="btn btn-primary w-auto"
                            disabled={disabled}
                            onClick={() => nextClicked(activeArrowTab)}
                        >
                            {activeArrowTab === 3 ? t('import.finish') : t('import.next')}
                        </Button>
                    </div>
                </div>
            </Offcanvas>
        </React.Fragment>
    );
};

export default withTranslation()(ImportFlow);
