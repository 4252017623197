import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Button } from "reactstrap";
import TableReport from "pages/Reports/Report/TableReport";
import KPIReport from "pages/Reports/Report/KPIReport";
import BarReport from "pages/Reports/Report/BarReport";
import LineReport from "pages/Reports/Report/LineReport";
import AreaReport from "pages/Reports/Report/AreaReport";
import PieReport from "pages/Reports/Report/PieReport";
import DonutReport from "pages/Reports/Report/DonutReport";
import ReportDetails from "pages/Reports/Report/ReportDetails";
import ChartLoader from "../ChartLoader";

interface ReportProps {
    showResult: boolean;
    config: any;
    onRun?: () => void;
    t: (key: string) => string;
}

const Report: React.FC<ReportProps> = ({
    showResult,
    config,
    onRun,
    t,
}) => {
    const [detailReportData, setDetailReportData] = useState(null)
    const setDetailReportDataClick = (data: any) => {
        setDetailReportData && setDetailReportData(data);
    }
    const handleChart = (chart: string) => {
        switch(chart) {
            case 'count':
                return (<KPIReport config={config} onDetailsReport={setDetailReportData} />)
            case 'bar':
                return (<BarReport config={config} onDetailsReport={setDetailReportData} />)
            case 'line':
                return (<LineReport config={config} onDetailsReport={setDetailReportData} />)
            case 'area':
                return (<AreaReport config={config} onDetailsReport={setDetailReportData} />)
            case 'pie':
                return (<PieReport config={config} onDetailsReport={setDetailReportData} />)
            case 'donut':
                return (<DonutReport config={config} onDetailsReport={setDetailReportData} />)
            case 'table':
                return (<TableReport config={config} />)
            default:
                return (<ChartLoader />)
        }
    }
    return (
        <div className="d-flex flex-column w-100 bg-light gap-4 h-100">
            {onRun ? <div className="d-flex justify-content-between">
                <Button
                    size="sm"
                    color="primary"
                    className="btn-label"
                    onClick={onRun}
                >
                        <i className="ri-flashlight-line label-icon align-middle fs-16 me-2"></i>
                        Run
                </Button>
            </div> : null}
            <div className="d-flex flex-wrap justify-content-center h-100 p-3">
                {showResult ? handleChart(config?.chart_type) : null}
            </div>
            <ReportDetails
                config={config}
                detailReportData={detailReportData}
                setDetailReportData={setDetailReportDataClick}
            />
        </div>
    );
};

export default withTranslation()(Report);
