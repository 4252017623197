/**
 * Converts snake_case or lowercase strings to Capitalized Words.
 * @param str The string to transform
 * @returns The transformed string with capitalized words
 */
const convertToTitleCase = (str: string): string => {
    return str
      .split('_') // Split by underscore
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(' '); // Join with a space
}

export default convertToTitleCase;