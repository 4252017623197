import React from "react";
import { withTranslation } from "react-i18next";
import { Responsive, WidthProvider } from "react-grid-layout";
import 'react-grid-layout/css/styles.css';
import { Layout } from "store/report/types";
import Report from "pages/Reports/Common/Config/Report";
import dashboardLoading from "common/data/dashboardLoading";
import { ButtonGroup, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";

const ResponsiveGridLayout = WidthProvider(Responsive);


interface DashboardProps {
    setSelectedReports: (reports: Layout[]) => void;
    onRemove: (key: string) => void;
    reports: Layout[];
    t: (key: string) => string;
}

const Dashboard = ({ t, reports, setSelectedReports, onRemove }: DashboardProps) => {
    const loading = useSelector((state: ApplicationState) => state.report.dashboard_loading);
    const onLayoutChange = (layout: any) => {
        // Create a new reports array based on the layout
        const updatedReports = reports.map(report => {
            // Find the corresponding layout item
            const item = layout.find((l: any) => l.i === report.layout.i);
            if (item) {
                // If the layout item exists, return a new report object with the updated layout
                return { ...report, layout: item };
            }
            return report; // Otherwise, return the existing report unchanged
        });
        setSelectedReports(updatedReports); // Update the state with the new reports array
    };
    if(loading) {
        return (
            <ResponsiveGridLayout
                className="layout grid-background"
                layouts={{ lg: dashboardLoading.map(r => r.layout) }}
                breakpoints={{ lg: 1280, md: 992, sm: 767, xs: 480, xxs: 0 }}
                cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                rowHeight={50}
                isDraggable={false}
                isResizable={false}
            >
                {dashboardLoading.map(report => (
                    <div key={report.key} className="d-flex flex-column gap-2 bg-white rounded shadow p-2">
                        <Report showResult={true} config={null} />
                    </div>
                ))}
            </ResponsiveGridLayout>
        )
    }
    return (
        <div className="w-100 h-100 overflow-auto">
            <div className="d-flex flex-column gap-4 w-100 min-h-100">
                <ResponsiveGridLayout
                    className="layout grid-background"
                    layouts={{ lg: reports.map(r => r.layout) }}
                    breakpoints={{ lg: 1280, md: 992, sm: 767, xs: 480, xxs: 0 }}
                    cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                    onLayoutChange={onLayoutChange}
                    rowHeight={50}
                    isDraggable
                    isResizable
                >
                    {reports.map(report => (
                        <div key={report.key} className="d-flex flex-column gap-2 bg-white rounded shadow p-2">
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 className="text-primary">{report?.title}</h5>
                                <ButtonGroup>
                                    <UncontrolledDropdown direction="start">
                                        <DropdownToggle tag="button" className="btn btn-primary btn-icon btn-ghost-primary" onMouseDown={ e => e.stopPropagation() }>
                                            <i className="cursor-pointer ri-more-2-fill fs-4"></i>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdownmenu-primary" onMouseDown={ e => e.stopPropagation() }>
                                            <DropdownItem onClick={() => onRemove(report.key)}>Remove</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </ButtonGroup>
                            </div>
                            <Report showResult={true} config={report?.report?.config} />
                        </div>
                    ))}
                </ResponsiveGridLayout>
            </div>
            <div className="w-100" style={{height: '240px'}}></div>
        </div>
    );
};

export default withTranslation()(Dashboard);
