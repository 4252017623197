
import import_svg from "../../../assets/images/svg/upload-data.svg";

export const importCards = [
    {
        "title": "Import",
        "description": "Import students, applications, institutes, courses information into Zilter.",
        "btnText": "Start an import",
        "svg": import_svg
    }
    // {
    //     "title": "Sync",
    //     "description": "Sync data between Zilter and dozens of other apps.",
    //     "btnText": "Setup to sync",
    //     "svg": import_svg
    // }
]