import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Config from "../Common/Config/Config";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ApplicationState } from "store";
import { reportGetRequest } from "store/report/action";

const Edit: React.FC = () => {
    const { id } = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const report_loading = useSelector((state: ApplicationState) => state.report.report_loading);
    const report = useSelector((state: ApplicationState) => state.report.report);

    useEffect(() => {
        if(id) {
            dispatch(reportGetRequest(id))
        }
    }, [id])

    return (
        <Config reportConfig={report} />
    );
};
export default Edit;
