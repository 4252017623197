import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Button, Col, Input, Label, Row, Table, Modal, ModalHeader, ModalBody } from "reactstrap";
import logo from "../../assets/images/dtsimg/svg/zilter_logo_white.svg";
import html2pdf from "html2pdf.js";
import moment from "moment";
import InPersonVerify from "./InPersonVerify";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { confirmRecommendations } from "store/recommendation/action";
import { SendEmail } from "store/applications/action";

const AuthorizationForm = () => {
    const { tenant, id } = useParams();
    const printRef = useRef();
    const location = useLocation();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const dataReceived = location.state;
    const [modalOpen, setModalOpen] = useState(false);
    const [isConfirm, setIsConfirm] = useState<boolean>(false);
    const [formData, setFomData] = useState<any>()

    // Toggle modal visibility
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    useEffect(() => {
        if (dataReceived) setFomData(dataReceived)
    }, [])

    console.log("formdata", formData)
    const studentData = {
        "first_name": "Vandita",
        "last_name": "Matte",
        "date_of_birth": "Florence Guzman",
        "country_of_nationality": "Maritza Blanda",
        "university_application_reference_number_ucas_id": "Maritza Blanda",
        "course_applied": "Maritza Blanda",
        "date_application_submitted": "Maritza Blanda",
        "your_agency": "Maritza Blanda",
        "reasons_for_taking_assistance": ""
    }

    const downloadPDF = () => {
        const element = document.getElementById("authorization-request-form"); // Ensure this ID matches your div's ID
        const options = {
            margin: 1,
            filename: "Agent_Request_Form.pdf",
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
        };

        html2pdf().set(options).from(element).save();
    };

    const onSend = () => {
        let toemail = dataReceived && dataReceived?.details?.valuesJson?.student?.email ? dataReceived?.details?.valuesJson?.student?.email : "";
        const data = {
            // parentId: emailinfo.id,
            studentId: dataReceived?.details?.valuesJson?.student?.id,
            applicationId: dataReceived?.details?.id,
            to: toemail,
            subject:  "Authorization mail",
            body: "https://qa.zilter.io/authority-letter/205a6c0a805c4a90bb4d2abbc17ba1ff/37b5fed8-def8-4c5e-b9c5-fb3f50b8db6f",
            // bcc: emailinfo?.bcc ? [...emailinfo?.bcc?.split(",")] : null,
            // cc: emailinfo?.cc ? [...emailinfo?.cc?.split(",")] : null
        }
        const handleSuccess = () => {
            // handleGetList()
            // setInnitial()
            // setEmailData({to: to,applicationId: applicationId, studentId: studentId })
            // setEmailOpen(false)
        }
        const handleFailure = () => { }
        dispatch(SendEmail(data, handleSuccess, handleFailure))
    }

    console.log("dataReceived", dataReceived)
    return (
        <>
            {/* Modal */}
            {/* <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
                <ModalHeader toggle={toggleModal}>Take Selfie</ModalHeader>
                <ModalBody>
                    <InPersonVerify />
                </ModalBody>
            </Modal> */}
            <div id="authorization-request-form">
                <div className="bg-dark">
                    <Row>
                        <Col lg={12}>
                            <div className="text-center mt-1 mb-4 text-white-50">
                                <div>
                                    <Link to="/#" className="d-inline-block auth-logo">
                                        <img src={logo} alt="" height="50" />
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                {/* <Button color="primary" onClick={downloadPDF} className="mt-4">
          Download as PDF
        </Button>
        <Button color="secondary" onClick={toggleModal} className="mt-4 ms-3">
          Open Selfie Modal
        </Button> */}
                <div className="mt-1 d-flex justify-content-center">
                    <Col xl={8}>
                        <div>
                            <Row>
                                <Label className="text-center mt-3 mb-5 fs-20">SI-UK Authorization Form</Label>
                            </Row>
                            <div className="mx-5 mb-2">
                                <Table className="table-bordered align-middle mb-0">
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "40%", whiteSpace: "nowrap" }}>First Name</td>
                                            <td style={{ width: "60%" }}>{dataReceived?.details?.valuesJson?.student?.first_name}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "40%", whiteSpace: "nowrap" }}>Last Name</td>
                                            <td style={{ width: "60%" }}>{dataReceived?.details?.valuesJson?.student?.last_name}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "40%", whiteSpace: "nowrap" }}>Date of Birth:</td>
                                            <td style={{ width: "60%" }}>{dataReceived?.details?.valuesJson?.student?.date_of_birth ? moment(dataReceived?.details?.valuesJson?.student?.date_of_birth).format("DD/MMM/YYYY") : ""}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "40%", whiteSpace: "nowrap" }}>Country of Nationality:</td>
                                            <td style={{ width: "60%" }}>{dataReceived?.details?.valuesJson?.student?.nationality}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "40%", whiteSpace: "nowrap" }}>University application reference Number/UCAS ID</td>
                                            <td style={{ width: "60%" }}>Maritza Blanda</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "40%", whiteSpace: "nowrap" }}>Course Applied</td>
                                            <td style={{ width: "60%" }}>{dataReceived?.details?.valuesJson?.courses?.name}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "40%", whiteSpace: "nowrap" }}>Date Application Submitted</td>
                                            <td style={{ width: "60%" }}>
                                                {dataReceived?.details?.createdAt ? moment(dataReceived.details?.createdAt).format("DD/MMM/YYYY") : ""}
                                            </td>

                                        </tr>
                                        <tr>
                                            <td style={{ width: "40%", whiteSpace: "nowrap" }}>Your Agency</td>
                                            <td style={{ width: "60%" }}>Maritza Blanda</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "40%", whiteSpace: "nowrap" }}>Reason(s) for taking assistance:</td>
                                            <td style={{ width: "60%" }}>
                                                <Input />
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                            <div className="mx-5">
                                <Row className="mt-3 mb-5">
                                    <Label className="mb-1 fs-14">
                                        Upon receipt of my completed form, I am aware and authorize the university to remove my current agent
                                        and change it in the student database to my proposed Agent (SI-UK). Based on my previous Privacy
                                        Declaration, (as applicable) all future correspondence will be sent to SI- UK.
                                    </Label>
                                </Row>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <Label>Signature: __________________________</Label>
                                    </div>
                                    <div>
                                        <Label>Date: {moment().format("MM/DD/YYYY")}</Label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </div>
            </div>
            <Button color="primary" onClick={onSend} className="mt-4">
                Send to student
            </Button>
        </>
    );
};

export default AuthorizationForm;
