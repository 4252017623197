import { Reducer } from "redux";
import { DocumentActionTypes, DocumentState } from "./types";


export const initialState: DocumentState =
{
    metaDataFields: [],
    list: null,
    details: null,
    pagination: null,
    loading: false,
    dataLoading: false,
    metaLoading: false
}
const reducer: Reducer<DocumentState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case DocumentActionTypes.GET_DOCUMENTMETADATA_REQUEST: {
            return { ...state, metaLoading: true };
        }
        case DocumentActionTypes.GET_DOCUMENTMETADATA_SUCCESS: {
            return {
                ...state,
                metaLoading: false,
                metaDataFields: action.payload.content
            };
        }
        case DocumentActionTypes.GET_DOCUMENTMETADATA_ERROR: {
            return { ...state, metaLoading: false, errors: action.payload };
        }
        case DocumentActionTypes.GET_DOCUMENT_REQUEST: {
            return { ...state, loading: true };
        }
        case DocumentActionTypes.GET_DOCUMENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                details: action.payload
            };
        }
        case DocumentActionTypes.GET_DOCUMENT_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        case DocumentActionTypes.POST_DOCUMENT_REQUEST: {
            return { ...state, loading: true };
        }
        case DocumentActionTypes.POST_DOCUMENT_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case DocumentActionTypes.POST_DOCUMENT_ERROR: {
            return { ...state, loading: false, errors: action.payload, list:[] };
        }

        case DocumentActionTypes.GET_DOCUMENTS_REQUEST: {
            return { ...state, dataLoading: true, list:[]  };
        }
        case DocumentActionTypes.GET_DOCUMENTS_SUCCESS: {
            return {
                ...state,
                dataLoading: false,
                list: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case DocumentActionTypes.GET_DOCUMENTS_ERROR: {
            return { ...state, dataLoading: false, errors: action.payload, list:[]  };
        }
        case DocumentActionTypes.PUT_DOCUMENT_REQUEST: {
            return { ...state, loading: true };
        }
        case DocumentActionTypes.PUT_DOCUMENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                documentlist: action.payload.content
            };
        }
        case DocumentActionTypes.PUT_DOCUMENT_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case DocumentActionTypes.DELETE_DOCUMENT_REQUEST: {
            return { ...state, loading: true };
        }
        case DocumentActionTypes.DELETE_DOCUMENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case DocumentActionTypes.DELETE_DOCUMENT_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        } 
        case DocumentActionTypes.DOWNLOAD_DOCUMENT_REQUEST: {
            return { ...state, loading: true };
        }
        case DocumentActionTypes.DOWNLOAD_DOCUMENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case DocumentActionTypes.DOWNLOAD_DOCUMENT_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }     
        case DocumentActionTypes.POST_PROFILEPICTURE_REQUEST: {
            return { ...state, loading: true };
        }
        case DocumentActionTypes.POST_PROFILEPICTURE_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case DocumentActionTypes.POST_PROFILEPICTURE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }    
        default: {
            return state;
        }
        case DocumentActionTypes.DOWNLOAD_STUDENT_BULKENTRY_REQUEST: {
            return { ...state, loading: true };
        }
        case DocumentActionTypes.DOWNLOAD_STUDENT_BULKENTRY_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case DocumentActionTypes.DOWNLOAD_STUDENT_BULKENTRY_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }  
        case DocumentActionTypes.DOWNLOAD_APPLICATION_BULKENTRY_REQUEST: {
            return { ...state, loading: true };
        }
        case DocumentActionTypes.DOWNLOAD_APPLICATION_BULKENTRY_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case DocumentActionTypes.DOWNLOAD_APPLICATION_BULKENTRY_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }  
         
        case DocumentActionTypes.DOWNLOAD_BULKENTRY_REQUEST: {
            return { ...state, loading: true };
        }
        case DocumentActionTypes.DOWNLOAD_BULKENTRY_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case DocumentActionTypes.DOWNLOAD_BULKENTRY_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }  
    }
};

export { reducer as DocumentReducer };