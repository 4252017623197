import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
    UncontrolledDropdown,
    Modal,
    ModalBody,
    Input,
    ModalHeader,
    Row,
    Col,
} from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useParams } from "react-router-dom";
import { SendEmail, getStudentsEmails } from "store/student/action";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import DtsEmailsInputs from "Components/Common/FormBuilder/DtsEmailsInputs";


const editorConfig = {
    removePlugins: ['Table', 'TableToolbar', 'TableTools', 'InsertTable'],
  };
export interface SuggestionOption {
    readonly value: string;
    readonly label: string;
}

interface FProps {
    emailModals: boolean;
    setEmailModals: (event: boolean) => void;
    info: any;
    record: any;
    model: any;
    to: any;
}

const EmailModal: React.FC<FProps> = ({
    emailModals,
    setEmailModals,
    record,
    info,
    model,
    to
}) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const { id } = useParams();
    const [isccOn, setisccOn] = useState(false)
    const [isBccOn, setisBccOn] = useState(false)
    const [disabled, setdisabled] = useState(true)
    const [studentId, setStudentId] = useState(null)
    const [applicationId, setApplicationId] = useState(null)
    const [emailData, setEmailData] = useState<any>({
        studentId: studentId,
        applicationId: applicationId,
        to: to
    })
    const handleGetList = () => {
        if(model == "students") {
            if (record && record.id) {
                setStudentId(record.id)
                dispatch(getStudentsEmails(record.id));
                setEmailData({...emailData,
                    studentId: id
                })
            }
        }
        else {
            if (record && record.id) {
                setApplicationId(record.id)
                let studentsId = record?.valuesJson?.student?.id;
                setStudentId(studentsId)
                setEmailData({...emailData,
                    studentId: studentsId,
                    applicationId: id,
                    to: record?.valuesJson?.email ||  record?.valuesJson?.student?.email
                })
                dispatch(getStudentsEmails(studentsId, {applicationId: record.id}))
            }
        }     
    }

    useEffect(() => {
        handleGetList()   
    }, [record])

    const toggle = () => {
        if (emailModals) {
            setEmailModals(false);
        } else {
            setEmailModals(true);
        }
    };

    const onSend = () => {
        const data = { ...emailData, parentId: "" }
        const handleSuccess = () => {
            handleGetList()
            setEmailModals(false)
            handleGetList()
        }
        const handleFailure = () => { }
        dispatch(SendEmail(data, handleSuccess, handleFailure))
    }

    useEffect(() => {
        if (isBccOn) {
            const getID = document.getElementById('BccRecipientsCollapse') as HTMLElement
            if (getID) getID.classList.add("show");
        }
        else {
            const getID = document.getElementById('BccRecipientsCollapse') as HTMLElement
            if (getID) getID.classList.remove("show");
        }
        if (isccOn) {
            const getID = document.getElementById('CcRecipientsCollapse') as HTMLElement
            if (getID) getID.classList.add("show");
        }
        else {
            const getID = document.getElementById('CcRecipientsCollapse') as HTMLElement
            if (getID) getID.classList.remove("show");
        }
    }, [isBccOn, isccOn])
  

    return (
        <React.Fragment>
            <Modal backdrop="static" id="composemodal" className="modal-lg" isOpen={emailModals} toggle={toggle} centered>
                <ModalHeader className="p-3 bg-light" toggle={() => { setEmailModals(false); }}>
                    New Message
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div className="mb-3 position-relative">
                            <Row className="hstack mb-3 position-relative">
                                <Col md={10} lg={10} className="pe-0">
                                    <DtsEmailsInputs placeholder={"To"} disabled={true} defaultValue={[`${emailData.to}`]} setdisabled={setdisabled} onChange={(values: any) => setEmailData({ ...emailData, to: values })} />
                                </Col>
                                <Col md={2} lg={2} className="ps-0 hstack justify-content-end">
                                    <div className="d-flex">
                                        <button
                                            className="btn btn-link text-reset fw-semibold px-2"
                                            type="button"
                                            onClick={() => setisccOn(!isccOn)}
                                        >
                                            Cc
                                        </button>
                                        <button
                                            className="btn btn-link text-reset fw-semibold px-2"
                                            type="button"
                                            onClick={() => setisBccOn(!isBccOn)}
                                        >
                                            Bcc
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        {
                            isccOn ?
                                <div className="collapse" id="CcRecipientsCollapse">
                                    <div className="mb-3">
                                        <label>Cc:</label>
                                        <DtsEmailsInputs placeholder={"Cc"} setdisabled={setdisabled} onChange={(values: any) => setEmailData({ ...emailData, cc: values })} />
                                    </div>
                                </div> :
                                null}
                        {
                            isBccOn ? <div className="collapse" id="BccRecipientsCollapse">
                                <div className="mb-3">
                                    <label>Bcc:</label>
                                    <DtsEmailsInputs placeholder={"Bcc"} setdisabled={setdisabled} onChange={(values: any) => setEmailData({ ...emailData, bcc: values })} />
                                </div>
                            </div>
                                : null
                        }

                        <div className="mb-3">
                            <Input type="text" value={emailData.subject || ""} className="form-control" placeholder="Subject" onChange={(e: any) => setEmailData({ ...emailData, subject: e.target.value })} />
                        </div>
                        <div className="ck-editor-reverse">
                            <CKEditor
                                editor={ClassicEditor}
                                config={editorConfig}
                                onReady={(editor) => {
                                    // You can store the "editor" and use when it is needed.

                                }}
                                onChange={(event: any, editor: any) => {
                                    const data = editor.getData();
                                    setEmailData({ ...emailData, body: data })
                                }}
                            />
                        </div>
                    </div>
                </ModalBody>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-ghost-danger"
                        onClick={() => {
                            setEmailModals(false);
                        }}
                    >
                        Discard
                    </button>

                    <UncontrolledDropdown className="btn-group">
                        <button
                            type="button"
                            className="btn btn-success"
                            disabled={disabled || !emailData.subject || !emailData.body}
                            onClick={() => {
                                onSend();
                            }}
                        >
                            Send
                        </button>
                    </UncontrolledDropdown>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default withTranslation()(EmailModal);
