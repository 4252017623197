const getChartColorsArray = (colors: string): string[] => {
    const parsedColors: string[] = JSON.parse(colors);
    
    return parsedColors.map(function (value: string) {
        var newValue = value.trim(); // Remove any extra spaces around the value

        if (newValue.indexOf(",") === -1) {
            // Handle CSS variables or hex codes
            var color = getComputedStyle(document.documentElement).getPropertyValue(newValue).trim();

            // Return the computed color if valid, otherwise return the original value
            if (color && color !== "") {
                return color;
            } else {
                return newValue;
            }
        } else {
            // Handle RGBA colors
            var val = value.split(',');
            if (val.length === 2) {
                var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]).trim();
                
                // If valid, construct the RGBA color, otherwise return the original
                if (rgbaColor) {
                    return `rgba(${rgbaColor},${val[1]})`;
                } else {
                    return newValue;
                }
            } else {
                return newValue; // Return original if it doesn't match expected format
            }
        }
    });
};

export default getChartColorsArray;
