import AgentRequestForm from "./AgentRequestForm";
import AuthorizationForm from "./AuthorizationForm";

const index = () => {
    return (
        <>
        {/* <AgentRequestForm /> */}
        <AuthorizationForm />
        </>
    )
}
export default index;