import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Report from "../Common/Config/Report";
import { useNavigate } from 'react-router-dom';
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ApplicationState } from "store";
import { reportGetRequest } from "store/report/action";
import BreadCrumb from "Components/Common/BreadCrumb";
import { Button, Container } from "reactstrap";
import { withTranslation } from "react-i18next";


interface ViewProps {
    t: (key: string) => string;
}
const View: React.FC<ViewProps> = ({t}) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const report = useSelector((state: ApplicationState) => state.report.report);

    useEffect(() => {
        if(id) {
            dispatch(reportGetRequest(id))
        }
    }, [id])

    const onEdit = () => {
        navigate(`/reports/edit/${id}`);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="d-flex align-items-center justify-content-between">
                        <BreadCrumb title={report?.name ? report?.name : ''} pageTitle={t("reports.reports")} pageTitleLink="/reports" />
                        <Button
                            onClick={() => onEdit()}
                            color="primary"
                            size='sm'
                            className="btn-label">
                                <i className="ri-user-add-fill label-icon align-middle fs-16 me-2"></i>
                                Edit
                        </Button>
                    </div>
                    <div className="d-flex w-100" style={{height: '70vh'}}>
                        <Report
                            showResult={true}
                            config={report?.config}
                        />
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(View);
