import { Reducer } from "redux";
import { PricingActionTypes, PricingState } from "./types";


export const initialState: PricingState =
{
    paymentLink: null, // or an empty string or whatever type it should be
    loading: false,
}
const reducer: Reducer<PricingState> = (state: any = initialState, action: any) => {
    switch (action.type) {

        case PricingActionTypes.GET_PLANLINK_REQUEST: {
            return { ...state, loading: true };
        }
        case PricingActionTypes.GET_PLANLINK_SUCCESS: {
            return {
                ...state,
                loading: false,
                paymentLink: action.payload.url
            };
        }
        case PricingActionTypes.GET_PLANLINK_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        default:
            return state; // Return the current state for unrecognized actions

    }
};

export { reducer as PricingReducer };
