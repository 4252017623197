import React from 'react';
import { withTranslation } from 'react-i18next';
import TableComponent from './TableComponent';


const RecentImports = (props: any) => {   
    const columns: any = []
    const data: any = []
    return (
        <React.Fragment>
            <div className='vstack justify-content-center gap-3'>
                <div className='hstack justify-content-center gap-3'>
                    <TableComponent 
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light text-muted"
                        thClass="border-bottom-1 table-soft-primary" 
                        columns={columns} data={data} />                   
                
                </div>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(RecentImports);
