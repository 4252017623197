import { ReportActionTypes } from "./types";
import { ActionCreator, Action, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { ApplicationState } from "../index";
import config from "../../config";

const { api } = config;
const DTS_API_REPORT = api.DTS_API_REPORT;

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const tablesGetRequest: ActionCreator<
  ThunkAction<void, ApplicationState, null, Action<string>>
> = (data) => {
  return async (dispatch: Dispatch, getState: any): Promise<void> => {
    const {
      auth: { token },
    } = getState();

    dispatch({
      type: ReportActionTypes.GET_TABLES_REQUEST,
    });

    try {
      const response = await fetch(`${DTS_API_REPORT}/api/metadata/tables`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data),
      });

      const body = await response.json();

      if (!response.ok) {
        dispatch({
          type: ReportActionTypes.GET_TABLES_ERROR,
          payload: body,
        });
      } else {
        dispatch({
          type: ReportActionTypes.GET_TABLES_SUCCESS,
          payload: body,
        });
      }
    } catch (error) {
      dispatch({
        type: ReportActionTypes.GET_TABLES_ERROR,
        payload: { error: "Failed to fetch data." },
      });
    }
  };
};

export const columnsGetRequest: ActionCreator<
  ThunkAction<void, ApplicationState, null, Action<string>>
> = (data) => {
  return async (dispatch: Dispatch, getState: any): Promise<void> => {
    const {
      auth: { token },
    } = getState();

    dispatch({
      type: ReportActionTypes.GET_COLUMNS_REQUEST,
    });

    try {
      const response = await fetch(`${DTS_API_REPORT}/api/metadata/tables/columns`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data),
      });

      const body = await response.json();

      if (!response.ok) {
        dispatch({
          type: ReportActionTypes.GET_COLUMNS_ERROR,
          payload: body,
        });
      } else {
        dispatch({
          type: ReportActionTypes.GET_COLUMNS_SUCCESS,
          payload: body,
        });
      }
    } catch (error) {
      dispatch({
        type: ReportActionTypes.GET_COLUMNS_ERROR,
        payload: { error: "Failed to fetch data." },
      });
    }
  };
};

export const reportGenerateRequest: ActionCreator<
  ThunkAction<void, ApplicationState, null, Action<string>>
> = (data, handleSuccess, handleError) => {
  return async (dispatch: Dispatch, getState: any): Promise<void> => {
    const {
      auth: { token },
    } = getState();

    dispatch({
      type: ReportActionTypes.GENERATE_REPORT_REQUEST,
    });

    try {
      const response = await fetch(`${DTS_API_REPORT}/api/reports/generate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data),
      });

      const body = await response.json();

      if (!response.ok) {
        dispatch({
          type: ReportActionTypes.GENERATE_REPORT_ERROR,
          payload: body,
        });
        handleError && handleError();
      } else {
        dispatch({
          type: ReportActionTypes.GENERATE_REPORT_SUCCESS,
          payload: body,
        });
        handleSuccess && handleSuccess(body);
      }
    } catch (error) {
      dispatch({
        type: ReportActionTypes.GENERATE_REPORT_ERROR,
        payload: { error: "Failed to fetch data." },
      });
      handleError && handleError();
    }
  };
};


export const reportDetailGetRequest: ActionCreator<
  ThunkAction<void, ApplicationState, null, Action<string>>
> = (data) => {
  return async (dispatch: Dispatch, getState: any): Promise<void> => {
    const {
      auth: { token },
    } = getState();

    dispatch({
      type: ReportActionTypes.GENERATE_DETAIL_REPORT_REQUEST,
    });

    try {
      const response = await fetch(`${DTS_API_REPORT}/api/reports/generate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data),
      });

      const body = await response.json();

      if (!response.ok) {
        dispatch({
          type: ReportActionTypes.GENERATE_DETAIL_REPORT_ERROR,
          payload: body,
        });
      } else {
        dispatch({
          type: ReportActionTypes.GENERATE_DETAIL_REPORT_SUCCESS,
          payload: body,
        });
      }
    } catch (error) {
      dispatch({
        type: ReportActionTypes.GENERATE_DETAIL_REPORT_ERROR,
        payload: { error: "Failed to fetch data." },
      });
    }
  };
};


export const reportClearRequest: ActionCreator<
  ThunkAction<void, ApplicationState, null, Action<string>>
> = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: ReportActionTypes.GET_REPORT_SUCCESS,
      payload: null,
    });
  };
};
export const reportFeatureStatusGetRequest: AppThunk = (id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: ReportActionTypes.GET_REPORTS_FEATURE_STATUS_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_REPORT}/api/schema/status`, {
                method: "GET",
                headers: {
                  'Authorization': 'Bearer ' + token
                },
            });
            const body = await response.json();
            if (!response.ok) {
                dispatch({
                    type: ReportActionTypes.GET_REPORTS_FEATURE_STATUS_ERROR,
                    payload: body
                });
            } else {
                dispatch({
                    type: ReportActionTypes.GET_REPORTS_FEATURE_STATUS_SUCCESS,
                    payload: body
                });
            }
        } catch (error) {
          dispatch({
              type: ReportActionTypes.GET_REPORTS_FEATURE_STATUS_ERROR,
              payload: null
          });
        }
    };
};


export const reportActivationRequest: ActionCreator<
  ThunkAction<void, ApplicationState, null, Action<string>>
> = (data, handleSuccess, handleError) => {
  return async (dispatch: Dispatch, getState: any): Promise<void> => {
    const {
      auth: { token },
    } = getState();

    dispatch({
      type: ReportActionTypes.GET_REPORTS_FEATURE_ACTIVATION_REQUEST,
    });

    try {
      const response = await fetch(`${DTS_API_REPORT}/api/schema/activate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data),
      });

      const body = await response.json();

      if (!response.ok) {
        dispatch({
          type: ReportActionTypes.GET_REPORTS_FEATURE_ACTIVATION_ERROR,
          payload: body,
        });
        handleError && handleError()
      } else {
        dispatch({
          type: ReportActionTypes.GET_REPORTS_FEATURE_ACTIVATION_SUCCESS,
          payload: body,
        });
        handleSuccess && handleSuccess()
      }
    } catch (error) {
      dispatch({
        type: ReportActionTypes.GET_REPORTS_FEATURE_ACTIVATION_ERROR,
        payload: { error: "Failed to fetch data." },
      });
      handleError && handleError()
    }
  };
};

export const reportSaveRequest: ActionCreator<
  ThunkAction<void, ApplicationState, null, Action<string>>
> = (data, handleSuccess, handleError) => {
  return async (dispatch: Dispatch, getState: any): Promise<void> => {
    const {
      auth: { token },
    } = getState();

    dispatch({
      type: ReportActionTypes.POST_REPORT_REQUEST,
    });

    try {
      const response = await fetch(`${DTS_API_REPORT}/api/reports`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data),
      });

      const body = await response.json();

      if (!response.ok) {
        dispatch({
          type: ReportActionTypes.POST_REPORT_ERROR,
          payload: body,
        });
        handleError && handleError();
      } else {
        dispatch({
          type: ReportActionTypes.POST_REPORT_SUCCESS,
          payload: body,
        });
        handleSuccess && handleSuccess();
      }
    } catch (error) {
      dispatch({
        type: ReportActionTypes.POST_REPORT_ERROR,
        payload: { error: "Failed to fetch data." },
      });
      handleError && handleError();
    }
  };
};

export const reportUpdateRequest: ActionCreator<
  ThunkAction<void, ApplicationState, null, Action<string>>
> = (id, data, handleSuccess, handleError) => {
  return async (dispatch: Dispatch, getState: any): Promise<void> => {
    const {
      auth: { token },
    } = getState();

    dispatch({
      type: ReportActionTypes.PUT_REPORT_REQUEST,
    });

    try {
      const response = await fetch(`${DTS_API_REPORT}/api/reports/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data),
      });

      const body = await response.json();

      if (!response.ok) {
        dispatch({
          type: ReportActionTypes.PUT_REPORT_ERROR,
          payload: body,
        });
        handleError && handleError();
      } else {
        dispatch({
          type: ReportActionTypes.PUT_REPORT_SUCCESS,
          payload: body,
        });
        handleSuccess && handleSuccess();
      }
    } catch (error) {
      dispatch({
        type: ReportActionTypes.PUT_REPORT_ERROR,
        payload: { error: "Failed to fetch data." },
      });
      handleError && handleError();
    }
  };
};

export const reportDeleteRequest: ActionCreator<
  ThunkAction<void, ApplicationState, null, Action<string>>
> = (id, handleSuccess, handleError) => {
  return async (dispatch: Dispatch, getState: any): Promise<void> => {
    const {
      auth: { token },
    } = getState();

    dispatch({
      type: ReportActionTypes.DELETE_REPORT_REQUEST,
    });

    try {
      const response = await fetch(`${DTS_API_REPORT}/api/reports/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + token
        },
      });

      if (!response.ok) {
        dispatch({
          type: ReportActionTypes.DELETE_REPORT_ERROR,
        });
        handleError && handleError();
      } else {
        dispatch({
          type: ReportActionTypes.DELETE_REPORT_SUCCESS,
        });
        handleSuccess && handleSuccess();
      }
    } catch (error) {
      dispatch({
        type: ReportActionTypes.DELETE_REPORT_ERROR,
        payload: { error: "Failed to fetch data." },
      });
      handleError && handleError();
    }
  };
};

export const reportsGetRequest: AppThunk = (search, page, size) => {
  return async (dispatch: Dispatch, getState): Promise<void> => {
      const { auth: { token } } = getState();
      dispatch({
          type: ReportActionTypes.GET_REPORTS_REQUEST
      });
      try {
          const response = await fetch(`${DTS_API_REPORT}/api/reports?search=${search || ''}&page=${page || 1}&size=${size || 100}`, {
              method: "GET",
              headers: {
                'Authorization': 'Bearer ' + token
              },
          });
          const body = await response.json();
          if (!response.ok) {
              dispatch({
                  type: ReportActionTypes.GET_REPORTS_ERROR,
                  payload: body
              });
          } else {
              dispatch({
                  type: ReportActionTypes.GET_REPORTS_SUCCESS,
                  payload: body
              });
          }
      } catch (error) {
        dispatch({
            type: ReportActionTypes.GET_REPORTS_ERROR,
            payload: null
        });
      }
  };
};

export const reportGetRequest: AppThunk = (id) => {
  return async (dispatch: Dispatch, getState): Promise<void> => {
      const { auth: { token } } = getState();
      dispatch({
          type: ReportActionTypes.GET_REPORT_REQUEST
      });
      try {
          const response = await fetch(`${DTS_API_REPORT}/api/reports/${id}`, {
              method: "GET",
              headers: {
                'Authorization': 'Bearer ' + token
              },
          });
          const body = await response.json();
          if (!response.ok) {
              dispatch({
                  type: ReportActionTypes.GET_REPORT_ERROR,
                  payload: body
              });
          } else {
              dispatch({
                  type: ReportActionTypes.GET_REPORT_SUCCESS,
                  payload: body
              });
          }
      } catch (error) {
        dispatch({
            type: ReportActionTypes.GET_REPORTS_ERROR,
            payload: null
        });
      }
  };
};

export const dashboardSaveRequest: ActionCreator<
  ThunkAction<void, ApplicationState, null, Action<string>>
> = (data, handleSuccess, handleError) => {
  return async (dispatch: Dispatch, getState: any): Promise<void> => {
    const {
      auth: { token },
    } = getState();

    dispatch({
      type: ReportActionTypes.POST_DASHBOARD_REQUEST,
    });

    try {
      const response = await fetch(`${DTS_API_REPORT}/api/dashboards`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data),
      });

      const body = await response.json();

      if (!response.ok) {
        dispatch({
          type: ReportActionTypes.POST_DASHBOARD_ERROR,
          payload: body,
        });
        handleError && handleError();
      } else {
        dispatch({
          type: ReportActionTypes.POST_DASHBOARD_SUCCESS,
          payload: body,
        });
        handleSuccess && handleSuccess();
      }
    } catch (error) {
      dispatch({
        type: ReportActionTypes.POST_DASHBOARD_ERROR,
        payload: { error: "Failed to fetch data." },
      });
      handleError && handleError();
    }
  };
};

export const dashboardsGetRequest: AppThunk = (search, page, size) => {
  return async (dispatch: Dispatch, getState): Promise<void> => {
      const { auth: { token } } = getState();
      dispatch({
          type: ReportActionTypes.GET_DASHBOARDS_REQUEST
      });
      try {
          const response = await fetch(`${DTS_API_REPORT}/api/dashboards?search=${search || ''}&page=${page || 1}&size=${size || 100}`, {
              method: "GET",
              headers: {
                'Authorization': 'Bearer ' + token
              }
          });
          const body = await response.json();
          if (!response.ok) {
              dispatch({
                  type: ReportActionTypes.GET_DASHBOARDS_ERROR,
                  payload: body
              });
          } else {
              dispatch({
                  type: ReportActionTypes.GET_DASHBOARDS_SUCCESS,
                  payload: body
              });
          }
      } catch (error) {
        dispatch({
            type: ReportActionTypes.GET_DASHBOARDS_ERROR,
            payload: null
        });
      }
  };
};

export const dashboardGetRequest: AppThunk = (id) => {
  return async (dispatch: Dispatch, getState): Promise<void> => {
      const { auth: { token } } = getState();
      dispatch({
          type: ReportActionTypes.GET_DASHBOARD_REQUEST
      });
      try {
          const response = await fetch(`${DTS_API_REPORT}/api/dashboards/${id}`, {
              method: "GET",
              headers: {
                'Authorization': 'Bearer ' + token
              },
          });
          const body = await response.json();
          if (!response.ok) {
              dispatch({
                  type: ReportActionTypes.GET_DASHBOARD_ERROR,
                  payload: body
              });
          } else {
              dispatch({
                  type: ReportActionTypes.GET_DASHBOARD_SUCCESS,
                  payload: body
              });
          }
      } catch (error) {
        dispatch({
            type: ReportActionTypes.GET_DASHBOARD_ERROR,
            payload: null
        });
      }
  };
};

export const dashboardUpdateRequest: ActionCreator<
  ThunkAction<void, ApplicationState, null, Action<string>>
> = (id, data, handleSuccess, handleError) => {
  return async (dispatch: Dispatch, getState: any): Promise<void> => {
    const {
      auth: { token },
    } = getState();

    dispatch({
      type: ReportActionTypes.PUT_DASHBOARD_REQUEST,
    });

    try {
      const response = await fetch(`${DTS_API_REPORT}/api/dashboards/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data),
      });

      const body = await response.json();

      if (!response.ok) {
        dispatch({
          type: ReportActionTypes.PUT_DASHBOARD_ERROR,
          payload: body,
        });
        handleError && handleError();
      } else {
        dispatch({
          type: ReportActionTypes.PUT_DASHBOARD_SUCCESS,
          payload: body,
        });
        handleSuccess && handleSuccess();
      }
    } catch (error) {
      dispatch({
        type: ReportActionTypes.PUT_DASHBOARD_ERROR,
        payload: { error: "Failed to fetch data." },
      });
      handleError && handleError();
    }
  };
};

export const dashboardDeleteRequest: ActionCreator<
  ThunkAction<void, ApplicationState, null, Action<string>>
> = (id, handleSuccess, handleError) => {
  return async (dispatch: Dispatch, getState: any): Promise<void> => {
    const {
      auth: { token },
    } = getState();

    dispatch({
      type: ReportActionTypes.DELETE_DASHBOARD_REQUEST,
    });

    try {
      const response = await fetch(`${DTS_API_REPORT}/api/dashboards/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + token
        },
      });

      if (!response.ok) {
        dispatch({
          type: ReportActionTypes.DELETE_DASHBOARD_ERROR,
        });
        handleError && handleError();
      } else {
        dispatch({
          type: ReportActionTypes.DELETE_DASHBOARD_SUCCESS,
        });
        handleSuccess && handleSuccess();
      }
    } catch (error) {
      dispatch({
        type: ReportActionTypes.DELETE_DASHBOARD_ERROR,
        payload: { error: "Failed to fetch data." },
      });
      handleError && handleError();
    }
  };
};