
const dashboardLoading = [{
    key: "loading_1",
    layout: {
        "w": 6,
        "h": 6,
        "x": 0,
        "y": 0,
        "i": "loading_1",
        "moved": false,
        "static": false
    }
}, {
    key: "loading_2",
    layout: {
        "w": 3,
        "h": 6,
        "x": 6,
        "y": 0,
        "i": "loading_2",
        "moved": false,
        "static": false
    }
}, {
    key: "loading_3",
    layout: {
        "w": 3,
        "h": 6,
        "x": 6,
        "y": 6,
        "i": "loading_3",
        "moved": false,
        "static": false
    }
}, {
    key: "loading_4",
    layout: {
        "w": 3,
        "h": 6,
        "x": 9,
        "y": 6,
        "i": "loading_4",
        "moved": false,
        "static": false
    }
}, {
    key: "loading_5",
    layout: {
        "w": 3,
        "h": 6,
        "x": 9,
        "y": 0,
        "i": "loading_5",
        "moved": false,
        "static": false
    }
}, {
    key: "loading_6",
    layout: {
        "w": 3,
        "h": 3,
        "x": 3,
        "y": 6,
        "i": "loading_6",
        "moved": false,
        "static": false
    }
}, {
    key: "loading_7",
    layout: {
        "w": 3,
        "h": 3,
        "x": 0,
        "y": 6,
        "i": "loading_7",
        "moved": false,
        "static": false
    }
}, {
    key: "loading_8",
    layout: {
        "w": 3,
        "h": 3,
        "x": 0,
        "y": 9,
        "i": "loading_8",
        "moved": false,
        "static": false
    }
}, {
    key: "loading_9",
    layout: {
        "w": 3,
        "h": 3,
        "x": 3,
        "y": 9,
        "i": "loading_9",
        "moved": false,
        "static": false
    }
}, {
    key: "loading_10",
    layout: {
        "w": 6,
        "h": 7,
        "x": 0,
        "y": 12,
        "i": "loading_10",
        "moved": false,
        "static": false
    }
}, {
    key: "loading_11",
    layout: {
        "w": 6,
        "h": 7,
        "x": 6,
        "y": 12,
        "i": "loading_11",
        "moved": false,
        "static": false
    }
}, {
    key: "loading_12",
    layout: {
        "w": 4,
        "h": 7,
        "x": 0,
        "y": 19,
        "i": "loading_12",
        "moved": false,
        "static": false
    }
}, {
    key: "loading_13",
    layout: {
        "w": 4,
        "h": 7,
        "x": 4,
        "y": 19,
        "i": "loading_13",
        "moved": false,
        "static": false
    }
}, {
    key: "loading_14",
    layout: {
        "w": 4,
        "h": 7,
        "x": 8,
        "y": 19,
        "i": "loading_14",
        "moved": false,
        "static": false
    }
}]

export default dashboardLoading;