import React from "react";
import { withTranslation } from "react-i18next";
import XAxis from "./common/XAxis";
import YAxis from "./common/YAxis";
import GroupBy from "./common/GroupBy";
import CompareBy from "./common/CompareBy";
import Fields from "./common/Fields";

interface LineConfigOptionsProps {
    groupBy: any;
    compareBy: any;
    xAxis: any;
    yAxis: any;
    fields: any;
    onModifyGroupBy: (property: any, dataSource: any) => void;
    onModifyCompareBy: (property: any, dataSource: any) => void;
    setCompareBy: (compareBy: any) => void;
    onModifyXAxis: (property: any, dataSource: any) => void;
    onModifyYAxis: (property: any, dataSource: any) => void;
    setXAxis: (xAxis: any) => void;
    moveFields: (dragIndex: number, hoverIndex: number, isDragging: boolean) => void
    onFieldUpdated: (newfilter: any) => void
    onFieldDelete: (deleteColumn: any) => void
    t: (key: string) => string;
}

const LineConfigOptions: React.FC<LineConfigOptionsProps> = ({
    t,
    groupBy,
    compareBy,
    xAxis,
    yAxis,
    fields,
    onModifyGroupBy,
    onModifyCompareBy,
    setCompareBy,
    onModifyXAxis,
    onModifyYAxis,
    setXAxis,
    moveFields,
    onFieldUpdated,
    onFieldDelete
}) => {
    return (
        <div className="d-flex flex-column gap-4">
            <XAxis
                xAxis={xAxis}
                onModifyXAxis={onModifyXAxis}
                setXAxis={setXAxis}
            />
            <YAxis
                yAxis={yAxis}
                onModifyYAxis={onModifyYAxis}
            />
            <GroupBy
                groupBy={groupBy}
                onModifyGroupBy={onModifyGroupBy}
            />
            <CompareBy
                compareBy={compareBy}
                onModifyCompareBy={onModifyCompareBy}
                setCompareBy={setCompareBy}
            />
            <Fields
                fields={fields}
                moveFields={moveFields}
                onFieldUpdated={onFieldUpdated}
                onFieldDelete={onFieldDelete}
            />
        </div>
    )
};

export default withTranslation()(LineConfigOptions);
