import React from "react";
import { Navigate } from "react-router-dom";

import Students from "pages/Students";
import Properties from "pages/Properties";
import ForgotPassword from "pages/Forgot/ForgetPassword";
import Joinuser from "pages/JoinUser/JoinUser";
import StudentsDetails from "pages/StudentsDetails";
import ResetPassword from "pages/ResetPassword/ResetPassword";
import Signin from "pages/Signin/signin";
import UsersList from "pages/UsersList";
import User from "pages/User/User";
import Signup from "pages/Signup/Signup";
import SignupSuccess from "pages/Signup/Success";
import UserDetailsModal from "pages/UsersList/User/UserDetailsModal";
import PrivacyPolicy from "../Privacy/PrivacyPolicy";
import FormBuilder from "../pages/FormBuilder/FormBuilder";
import Form from "../pages/FormBuilder/Form";
import Application from "../pages/Applications";
import Universities from "../pages/Universitites";
import ApplicationDetails from "../pages/ApplicationsDetails";
import UserPreferences from "pages/UserPreferences";
import Courses from "../pages/Courses";
import Dashboards from "../pages/Dashboards";
import Dashboard from "../pages/Dashboards/Dashboard";
import DashboardCreate from "../pages/Dashboards/Create";
import DashboardEdit from "../pages/Dashboards/Edit"
import AIDashboard from "../pages/Dashboards/Dashboard1"
import CourseDetails from "../pages/CourseDetails";
import UniversityDetails from "../pages/UniversityDetails";
import Sources from "../pages/Sources";
import Channels from "../pages/Channel";
import SourceDetails from "../pages/SourceDetails";
import CompanyDetails from "../pages/CompanyDetails";
import ChannelDetails from "../pages/ChannelDetails";
import UploadDocuments from "pages/UploadDocuments/UploadDocuments";
import Companies from "../pages/Companies";
import Charts from "../pages/Charts/ECharts"
import Invoices from "pages/ChannelInvoices";
import InvoiceCreate from "pages/ChannelInvoices/InvoiceCreate";
import StudentsInvoices from "pages/StudentInvoices";
import StudentsInvoiceCreate from "pages/StudentInvoices/InvoiceCreate";
import Recommendations from "pages/Recommendations";
import Textract from "../Components/Common/Textract/textract";
import CustomForms from "../pages/Forms";
import CustomForm from "../pages/Forms/Form";
import CustomFormsBuilder from "../pages/Forms/FormBuilder";
import EditForm from "../pages/Forms/EditForm";
import Subagent from "../pages/SubAgents"
import SubagentDetails from "../pages/SubAgentDetails"
import SessionError from "../Components/Common/SessionError"
import Roles from "../pages/Roles/Index"
import Reports from "../pages/Reports"
import CreateReport from "../pages/Reports/Create"
import EditReport from "../pages/Reports/Edit";
import ViewReport from "../pages/Reports/View";
import BankDetails from "../pages/CompanyDetails/BankDetails"
import Products from "../pages/Products"
import ProductDetails from "../pages/ProductDetails";
import Templates from "../pages/Templates";
import TemplatesDetails from "../pages/TemplatesDetails";
import DealsDetails from "../pages/DealDetails";
import PricingModel from "pages/Pricing/PricingModel";
import Import from "../Components/Common/Import";
import MultipleSignin from "pages/MultipleSignIn/MultipleSignin";
import AgentRequestForm from "pages/AuthLetters/AgentRequestForm";
import AuthLetters from "pages/AuthLetters";
import AuthorizationLetters from "pages/AuthorizationLetters";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/dashboards", component: <Dashboards /> },
  { path: "/dashboards/create", component: <DashboardCreate /> },
  { path: "/dashboards/edit/:id", component: <DashboardEdit /> },
  { path: "/Students", component: <Students /> },
  { path: "/roles", component: <Roles /> },
  { path: "/user" , component: <User /> },
  { path: "/students/:id", component: <StudentsDetails /> },
  { path: "/fields", component: <Properties /> },
  { path: "/users", component: <UsersList /> },
  { path: "/users/:id", component: <UserDetailsModal /> },
  { path: "/privacy-policy", component: <PrivacyPolicy /> },
  { path: "/form", component: <Form /> },
  { path: "/form-builder", component: <FormBuilder /> },
  { path: "/courses", component: <Courses /> },
  { path: "/courses/:id", component: <CourseDetails /> },
  { path: "/institutes", component: <Universities /> },
  { path: "/institutes/:id", component: <UniversityDetails /> },
  { path: "/applications", component: <Application /> },
  { path: "/sources", component: <Sources /> },
  { path: "/channels", component: <Channels /> },
  { path: "/applications/:id", component: <ApplicationDetails /> },
  { path: "/user-preferences/:id", component: <UserPreferences /> },
  { path: "/sources/:id", component: <SourceDetails /> },
  { path: "/products/:id", component: <ProductDetails /> },
  { path: "/channels/:id", component: <ChannelDetails /> },
  { path: "/charts", component: <Charts /> },
  { path: "/ai-dashborad", component: <AIDashboard /> },
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/channels/invoices", component: <Invoices /> },
  { path: "/channels/invoices/create-invoice", component: <InvoiceCreate /> },
  { path: "/students/invoices", component: <StudentsInvoices /> },
  { path: "/students/invoices/create-invoice", component: <StudentsInvoiceCreate /> },
  { path: "/companies", component: <Companies /> },
  { path: "/companies/:id", component: <CompanyDetails /> },
  { path: "/companies/:id/banks/:childId", component: <BankDetails /> },
  { path: "/textract", component: <Textract /> },
  { path: "/forms", component: <CustomForms /> },
  { path: "/subagents", component: <Subagent /> },
  { path: "/products", component: <Products /> },
  { path: "/templates", component: <Templates /> },
  { path: "/templates/:id", component: <TemplatesDetails /> },
  { path: "/deals/:id", component: <DealsDetails /> },
  { path: "/subagents/:id", component: <SubagentDetails /> },
  { path: "/forms/custom-form-builder", component: <CustomFormsBuilder /> },
  { path: "/forms/edit/:tenantId/:id/", component: <EditForm /> },
  { path: "/forms/view/:tenantId/:id/", component: <EditForm /> },
  { path: "/reports", component: <Reports /> },
  { path: "/reports/create", component: <CreateReport /> },
  { path: "/reports/edit/:id", component: <EditReport /> },
  { path: "/reports/:id", component: <ViewReport /> },
  { path: "/import", component: <Import /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/students" />,
  },
  { path: "*", component: <Navigate to="/students" /> },
];

const publicRoutes = [
  { path: "/signup", component: <Signup /> },
  { path: "/signup/success", component: <SignupSuccess /> },
  { path: "/signup/error", component: <SignupSuccess /> },
  { path: "/Signin", component: <Signin /> },
  { path: "/password/forgot", component: <ForgotPassword /> },
  { path: "/join/:id", component: <Joinuser /> },
  { path: "/password/reset/:id", component: <ResetPassword /> },
  { path: "/students/forms/:id", component: <UploadDocuments /> },
  { path: "/recommendations/:tenant/:id", component: <Recommendations /> },
  { path: "/authority-letter/:accessToken/:applicationId", component: <AuthorizationLetters /> },
  { path: "/forms/:tenantId/:id", component: <CustomForm /> },
  { path: "/session-expired", component: <SessionError /> },
  { path: "/pricing", component: <PricingModel /> },
  { path: "/my-accounts", component: <MultipleSignin /> },
  { path: "/authorization-letters", component: <AuthLetters /> },
];

export { authProtectedRoutes, publicRoutes };
