export interface Student {
    list: any;
    name: string;
    last_name: string;
    image_url: string;
    phone_number: string;
    email: string;
    website: string;
    company_name:string;
    no_of_employees: string;
}
export interface Pagination {
    size: number,
    page: number,
    pages: number,
    total: number,
    elements: number,
    first: boolean,
    last: boolean
  }
  
  export interface Pagination {
    size: number,
    page: number,
    pages: number,
    total: number,
    elements: number,
  }

export enum PropertiesActionTypes {
    GET_PROPERTIES_REQUEST = "@@property/GET_PROPERTIES_REQUEST",
    GET_PROPERTIES_SUCCESS = "@@property/GET_PROPERTIES_SUCCESS",
    GET_PROPERTIES_ERROR = "@@property/GET_PROPERTIES_ERROR",
    GET_ARCHIVED_PROPERTIES_REQUEST = "@@property/GET_ARCHIVED_PROPERTIES_REQUEST",
    GET_ARCHIVED_PROPERTIES_SUCCESS = "@@property/GET_ARCHIVED_PROPERTIES_SUCCESS",
    GET_ARCHIVED_PROPERTIES_ERROR = "@@property/GET_ARCHIVED_PROPERTIES_ERROR",
    GET_PROPERTY_REQUEST = "@@property/GET_PROPERTY_REQUEST",
    GET_PROPERTY_SUCCESS = "@@property/GET_PROPERTY_SUCCESS",
    GET_PROPERTY_ERROR = "@@property/GET_PROPERTY_ERROR",
    PUT_PROPERTY_REQUEST = "@@property/PUT_PROPERTY_REQUEST",
    PUT_PROPERTY_SUCCESS = "@@property/PUT_PROPERTY_SUCCESS",
    PUT_PROPERTY_ERROR = "@@property/PUT_PROPERTY_ERROR",
    POST_PROPERTY_REQUEST = "@@property/POST_PROPERTY_REQUEST",
    POST_PROPERTY_SUCCESS = "@@property/POST_PROPERTY_SUCCESS",
    POST_PROPERTY_ERROR = "@@property/POST_PROPERTY_ERROR",
    DELETE_PROPERTY_REQUEST = "@@property/DELETE_PROPERTY_REQUEST",
    DELETE_PROPERTY_SUCCESS = "@@property/DELETE_PROPERTY_SUCCESS",
    DELETE_PROPERTY_ERROR = "@@property/DELETE_PROPERTY_ERROR",
    GET_GROUPS_REQUEST = "@@property/GET_GROUPS_REQUEST",
    GET_GROUPS_SUCCESS = "@@property/GET_GROUPS_SUCCESS",
    GET_GROUPS_ERROR = "@@property/GET_GROUPS_ERROR",
    GET_GROUPS_MODEL_REQUEST = "@@property/GET_GROUPS_MODEL_REQUEST",
    GET_GROUPS_MODEL_SUCCESS = "@@property/GET_GROUPS_MODEL_SUCCESS",
    GET_GROUPS_MODEL_ERROR = "@@property/GET_GROUPS_MODEL_ERROR",
    GET_GROUP_REQUEST = "@@property/GET_GROUP_REQUEST",
    GET_GROUP_SUCCESS = "@@property/GET_GROUP_SUCCESS",
    GET_GROUP_ERROR = "@@property/GET_GROUP_ERROR",
    POST_GROUP_REQUEST = "@@property/POST_GROUP_REQUEST",
    POST_GROUP_SUCCESS = "@@property/POST_GROUP_SUCCESS",
    POST_GROUP_ERROR = "@@property/POST_GROUP_ERROR",
    DELETE_GROUP_REQUEST = "@@property/DELETE_GROUP_REQUEST",
    DELETE_GROUP_SUCCESS = "@@property/DELETE_GROUP_SUCCESS",
    DELETE_GROUP_ERROR = "@@property/DELETE_GROUP_ERROR",
    RESTORED_PROPERTY_REQUEST = "@@property/RESTORED_PROPERTY_REQUEST",
    RESTORED_PROPERTY_SUCCESS = "@@property/RESTORED_PROPERTY_SUCCESS",
    RESTORED_PROPERTY_ERROR = "@@property/RESTORED_PROPERTY_ERROR",
    PUT_GROUP_REQUEST = "@@property/PUT_GROUP_REQUEST",
    PUT_GROUP_SUCCESS = "@@property/PUT_GROUP_SUCCESS",
    PUT_GROUP_ERROR = "@@property/PUT_GROUP_ERROR",
    GET_GROUPS_STUDENTFORM_REQUEST = "@@GET_GROUPS_STUDENTFORM_REQUEST",
    GET_GROUPS_STUDENTFORM_SUCCESS = "@@GET_GROUPS_STUDENTFORM_SUCCESS",
    GET_GROUPS_STUDENTFORM_ERROR = "@@GET_GROUPS_STUDENTFORM_ERROR",
    GET_ALLGROUPS_REQUEST = "@@GET_ALLGROUPS_REQUEST",
    GET_ALLGROUPS_SUCCESS = "@@GET_ALLGROUPS_SUCCESS",
    GET_ALLGROUPS_ERROR = "@@GET_ALLGROUPS_ERROR",
    PUT_BULK_PROPERTY_REQUEST = "@@PUT_BULK_PROPERTY_REQUEST",
    PUT_BULK_PROPERTY_SUCCESS = "@@PUT_BULK_PROPERTY_SUCCESS",
    PUT_BULK_PROPERTY_ERROR = "@@PUT_BULK_PROPERTY_ERROR"

}

export interface PropertiesState {
    readonly loading: boolean;
    readonly errors?: string;
    readonly response?: any;
    readonly token?: string;
    readonly permissionsdata?: any;
    readonly list?: any;
    readonly achivedlist?: any;
    readonly property?: any;
    readonly pagination: Pagination | null;
    readonly achivedpagination: Pagination | null;
    readonly groups: any;
    readonly groupsForm: any;
    readonly allGroups: any;
    readonly groupspagination: any;
    readonly groupsloading: any;
    readonly archivedloading: any;
}
