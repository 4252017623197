import React, { useState } from "react";
import { Button, Input, Label, Modal, ModalBody } from "reactstrap";


const CallSelectionModel = ({ props, show, onCloseClick, record , onCallClicked, isCallLoading}: any) => {
    const [method, setMethod] = useState<any>("1")
    return ( record && (record?.id || record?.mobile || record?.student?.mobile) ?
        <Modal backdrop="static" isOpen={show} toggle={onCloseClick} centered={true}>
                <ModalBody className='text-center p-5'>
                    <div className="mt-1">     
                        <i className="ri-phone-line display-5 text-primary"></i>                                  
                        <h4 className="mb-2 mt-2">Select Calling Method</h4>
                        <div className="form-check form-radio-primary mb-3">
                            <Input className="form-check-input" type="radio" name="formradiocolor1" onChange={() => setMethod("1")} checked={method === "1"} />
                            <Label className="form-check-label" for="formradioRight5">Mobile</Label>
                        </div>
                        <div className="form-check form-radio-primary mb-3">
                            <Input className="form-check-input" type="radio" name="formradiocolor1" onChange={() => setMethod("2")} checked={method === "2"}   />
                            <Label className="form-check-label" for="formradioRight5">VOIP</Label>
                        </div>
                        <div className="hstack gap-2 justify-content-center">
                            <Button color="primary" disabled={isCallLoading} onClick={() => onCallClicked(record, method)}>
                                { isCallLoading ? "Calling..." : "Call" }
                            </Button>
                            <Button color="primary" onClick={() => onCloseClick()} outline>
                                {props.t("properties.cancel")}
                            </Button>
                        </div>
                    </div>
                </ModalBody>
        </Modal> : null
    ) as unknown as JSX.Element;
};

export default CallSelectionModel;